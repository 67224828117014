import React, { useEffect, Fragment, Suspense } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PageLoader } from '../../utils/components';
import { loadUser, setURL } from '../../actions/auth';
import { getConnections } from '../../actions/connections';
import Navbar from '../../widgets/Navbar/Navbar';
import MainRequests from '../layout/MainRequests';
import { checkOldConnector, checkRoleAccess } from './Route.service';

const PrivateRoute = ({
  component: Component,
  componentProps,
  auth: { isAuthenticated, user, auth_loading, url, profile },
  connections: { loading },
  loadUser,
  setURL,
  getConnections,
  ...rest
}) => {
  const location = useLocation();

  useEffect(() => {
    if (!user.email) {
      loadUser();
      getConnections();
    }
  }, [loadUser, user.email, getConnections]);

  useEffect(() => {
    if (!url) {
      setURL(`${location.pathname}${location.search}`);
    }
  }, [url, location, setURL]);

  if (auth_loading || loading) {
    return <PageLoader height="90vh" />;
  } else {
    if (!isAuthenticated) return <Redirect to="/login/" />;
    if (!profile.connector && checkOldConnector(location.pathname))
      return <Redirect to="/dashboard/" />;
    if (!checkRoleAccess(user.role, location.pathname))
      return <Redirect to="/dashboard/" />;

    return (
      <Route
        {...rest}
        render={(props) => (
          <Fragment>
            <MainRequests />
            <Navbar />
            <Suspense fallback={<PageLoader height="90vh" />}>
              {componentProps ? componentProps : <Component {...props} />}
            </Suspense>
          </Fragment>
        )}
      />
    );
  }
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  connections: state.connections
});

export default connect(mapStateToProps, {
  loadUser,
  setURL,
  getConnections
})(PrivateRoute);
