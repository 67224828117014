import { useMemo } from 'react';
import { connect } from 'react-redux';
import { getActionStatus } from '../../../utils/constants';
import { InfoTooltip, PlayBtn } from '../../../utils/components';
import { runAction } from '../../../actions/connector';
import { actionHeadersOld } from '../ActionOld.data';
import { formTableRow } from '../../../utils/tables';

const ActionHeaderOld = ({
  connector: { actionsSetRun },
  actionData,
  runAction
}) => {
  const actionStatus = useMemo(
    () => (actionData ? getActionStatus(actionData.status) : null),
    [actionData]
  );

  const runCurrentAction = (e) => {
    runAction(
      e.currentTarget.getAttribute('data-id'),
      actionData?.name,
      actionData?.group_name
    );
  };

  if (!actionData) return null;

  return (
    <div className="action-header-box action-header-box-old">
      <div
        className="standard-table-header"
        style={{
          gridTemplateColumns: formTableRow(actionHeadersOld)
        }}
      >
        {actionHeadersOld.map((h, i) => (
          <div
            key={`action-table-header-${i}`}
            className="actions-table-header-cell"
          >
            {h.label}
            {h.text ? <InfoTooltip text={h.text} /> : null}
          </div>
        ))}
      </div>
      <div className="actions-table-body">
        <div
          className="standard-table-row"
          style={{
            gridTemplateColumns: formTableRow(actionHeadersOld)
          }}
        >
          <div className="actions-table-body-cell">{actionData.id}</div>
          <div className="actions-table-body-cell">
            {actionStatus ? actionStatus.img : 'Ready'}
          </div>
          <div className="actions-table-body-cell">{actionData.last_run}</div>
          <div className="actions-table-body-cell">{actionData.next_run}</div>
          <div className="actions-table-body-cell">
            <PlayBtn
              id={actionData.id}
              status={actionData.status}
              actionsSetRun={actionsSetRun}
              runFunction={runCurrentAction}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  connector: state.connector,
  user: state.auth.user
});
export default connect(mapStateToProps, { runAction })(ActionHeaderOld);
