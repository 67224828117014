import axios from 'axios';
import { actionErrorHandler } from '../utils/services';
import { setAlert } from './alert';
import {
  GET_ALL_SHARED_ACCESS,
  CLEAR_SHARED_ACCESS,
  SET_SHARED_ACCESS_LOADING,
  CHANGE_SHARED_ACCESS,
  DELETE_SHARED_ACCESS,
  CHECK_NEW_USER_TOKEN
} from './types';

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

//Get All Shared Access records
export const getAllSharedAccess = (account) => async (dispatch) => {
  try {
    const res = await axios.get(
      account ? `/api/shareAccess?account=${account}` : '/api/shareAccess'
    );

    dispatch({
      type: GET_ALL_SHARED_ACCESS,
      payload: res.data
    });
  } catch (err) {
    actionErrorHandler(err, dispatch, setAlert);
    dispatch({
      type: GET_ALL_SHARED_ACCESS,
      payload: []
    });
  }
  return true;
};

//Send Invitation / Create Shared access
export const createSharedAccess = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SHARED_ACCESS_LOADING,
      payload: true
    });

    const body = JSON.stringify(data);
    const res = await axios.post('/api/shareAccess', body, config);

    dispatch({
      type: SET_SHARED_ACCESS_LOADING,
      payload: false
    });
    dispatch(setAlert(`Message sent to ${data?.email}`, 'success', 5000));
    return res.data;
  } catch (err) {
    actionErrorHandler(err, dispatch, setAlert);
  }
  dispatch({
    type: SET_SHARED_ACCESS_LOADING,
    payload: false
  });
  return null;
};

//Update Shared Access record
export const updateSharedAccess = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SHARED_ACCESS_LOADING,
      payload: true
    });

    const body = JSON.stringify(data);
    const res = await axios.put(`/api/shareAccess/${id}`, body, config);
    dispatch({
      type: CHANGE_SHARED_ACCESS,
      payload: res.data
    });
    dispatch(
      setAlert('Shared User Access changed successfully!', 'success', 5000)
    );
    return res.data;
  } catch (err) {
    actionErrorHandler(err, dispatch, setAlert);
  }
  dispatch({
    type: SET_SHARED_ACCESS_LOADING,
    payload: false
  });
  return null;
};

export const deleteSharedAccess = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SHARED_ACCESS_LOADING,
      payload: true
    });

    await axios.delete(`/api/shareAccess/${id}`);

    dispatch({
      type: DELETE_SHARED_ACCESS,
      payload: id
    });
    dispatch(setAlert('Access deleted successfully!', 'success', 5000));
    return id;
  } catch (err) {
    actionErrorHandler(err, dispatch, setAlert);
  }
  dispatch({
    type: SET_SHARED_ACCESS_LOADING,
    payload: false
  });
  return null;
};

export const clearSharedAccess = () => (dispatch) => {
  dispatch({
    type: CLEAR_SHARED_ACCESS
  });
};

//Create Shared User
export const createSharedUser = (token, data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SHARED_ACCESS_LOADING,
      payload: true
    });

    const body = JSON.stringify(data);
    await axios.post('/api/shareAccess/newUser', body, {
      headers: { 'x-auth-token': token, 'Content-Type': 'application/json' }
    });

    dispatch({
      type: SET_SHARED_ACCESS_LOADING,
      payload: false
    });
    dispatch(setAlert('User created successfully!', 'success', 5000));
    return true;
  } catch (err) {
    actionErrorHandler(err, dispatch, setAlert);
  }
  dispatch({
    type: SET_SHARED_ACCESS_LOADING,
    payload: false
  });
  return false;
};

//Check New User Token
export const checkUserToken = (token) => async (dispatch) => {
  try {
    const res = await axios.get('/api/shareAccess/newUser', {
      headers: {
        'x-auth-token': token
      }
    });

    dispatch({
      type: CHECK_NEW_USER_TOKEN,
      payload: res.data
    });
  } catch (err) {
    actionErrorHandler(err, dispatch, setAlert);
    dispatch({
      type: CHECK_NEW_USER_TOKEN,
      payload: null
    });
  }
};
