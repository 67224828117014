export const formActionTabUrl = (path) => {
  const pathArr = path ? path.split('/') : [];
  return pathArr.filter((step, i) => i !== pathArr.length - 2).join('/');
};

export const downloadCSVfile = ({ headers, data, fileName }) => {
  const csvString = [headers, ...data].map((e) => e.join(',')).join('\n');

  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const fileUrl = URL.createObjectURL(blob);
  link.setAttribute('href', fileUrl);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadCSV = (headers, logs, fileName) => {
  const logsData = [];
  logs.forEach((log) => {
    logsData.push(
      headers.map((h) =>
        log[h.key] ? String(log[h.key]).replace(/,/g, ' ') : ''
      )
    );
  });

  downloadCSVfile({
    headers: headers.map((h) => h.label),
    data: logsData,
    fileName: `${fileName}_${new Date().getTime()}.csv`
  });
};

export const formGridRow = (headers) => {
  let row = '';
  if (Array.isArray(headers)) {
    headers.forEach((h, i) => {
      if (h.width) {
        row += i ? ` ${h.width}` : `${h.width}`;
      }
    });
  }

  return row;
};

export const setTimerRedirect = (seconds, setSeconds, setRedirect) => {
  setTimeout(() => {
    setSeconds(seconds - 1);
    if (seconds > 1) {
      setTimerRedirect(seconds - 1, setSeconds, setRedirect);
    } else {
      setRedirect(true);
    }
  }, 1000);
};

export const cutString = (value, count = 300) =>
  value?.length > count ? `${value.substring(0, count)}...` : value;

export const actionErrorHandler = (err, dispatch, setAlert, loadingType) => {
  if (err.response) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
  } else if (err.message === undefined) {
    dispatch({ type: loadingType });
  } else {
    console.log(err);
  }
};
