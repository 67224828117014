import { useRef, useEffect } from 'react';

const ActionDescription = ({ description }) => {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = description;
    }
  }, [description]);
  return <div className='action-description' ref={ref}></div>;
};

export default ActionDescription;
