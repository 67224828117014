export const setTableFilter = (e, headers) => {
  let prop = null;
  let method = null;
  const asc = 'active-arrow-asc';
  const dsc = 'active-arrow-dsc';
  const iconStyle = 'filter-arrow';
  const label = e.target.textContent.trim();
  const icon = e.target.parentNode.querySelector(`svg.${iconStyle}`);

  if (!icon) {
    return null;
  }

  //CSS styles logic
  if (icon.classList.contains(asc)) {
    method = 'dsc';
  }

  if (icon.classList.contains(dsc) && !method) {
    method = 'asc';
  }

  if (!icon.classList.contains(asc) && !icon.classList.contains(dsc)) {
    method = 'asc';
  }

  headers.forEach((h) => {
    if (h.label === label) {
      prop = h.key;
    }
  });

  return {
    prop,
    method
  };
};

export const formTableRow = (headers) => {
  let row = '';
  if (Array.isArray(headers)) {
    headers.forEach((h, i) => {
      if (h.width) {
        row += i ? ` ${h.width}` : `${h.width}`;
      }
    });
  }

  return row;
};
