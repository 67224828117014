import { connect } from 'react-redux';
import { Alert, AlertTitle } from '@material-ui/lab';

const AlertBlock = ({ alerts }) => {
  if (alerts) {
    if (alerts.length > 0) {
      return (
        <div className='alert-container'>
          {alerts.map((alert) => (
            <Alert severity={alert.alertType} key={alert.id}>
              <AlertTitle>{`${alert.alertType[0].toUpperCase()}${alert.alertType.slice(
                1
              )}`}</AlertTitle>
              {alert.msg}
            </Alert>
          ))}
        </div>
      );
    } else {
      return <div></div>;
    }
  } else {
    return <div></div>;
  }
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(AlertBlock);
