import { useState, useEffect, useMemo } from 'react';
import ScheduleMethods from './ScheduleMethods';
import { InfoTooltip } from '../../../utils/components';
import { MenuItem, Select } from '@material-ui/core';
import { getTimezoneHours } from '../../../utils/constants';

const EveryHour = ({ timezone, setCron, defaultCron }) => {
  const [formData, setFormData] = useState({
    everyHour: ScheduleMethods.getCronHourMin(defaultCron, 1),
    everyDayHour: ScheduleMethods.getCronHours(
      defaultCron,
      getTimezoneHours(timezone)
    ),
    everyDayMin: ScheduleMethods.getCronMinute(defaultCron),
  });

  const { everyHour, everyDayHour, everyDayMin } = formData;

  const onChangeHour = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      everyDayHour: [],
      everyDayMin: '',
    });
  };

  const onChangeDayHour = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      everyHour: '',
    });
  };

  useEffect(() => {
    const isHour = everyHour !== '';
    if (isHour || everyDayHour.length) {
      setCron(
        ScheduleMethods.calculateHourCron({
          time: isHour ? everyHour : everyDayHour,
          isHour,
          timezone: getTimezoneHours(timezone),
          min: everyDayMin,
        })
      );
    }
  }, [timezone, everyHour, everyDayHour, everyDayMin, setCron]);

  const MenuProps = useMemo(
    () => ({
      classes: { paper: 'select-list' },
      variant: 'menu',
      getContentAnchorEl: null,
    }),
    []
  );

  return (
    <ul>
      <li className='schedule-main-row schedule-subrow'>
        <p className='schedule-row-name bold'>Repeat every</p>
        <Select
          classes={{
            root: 'settings-edit-select',
          }}
          MenuProps={MenuProps}
          name='everyHour'
          value={everyHour}
          onChange={onChangeHour}
          className='schedule-select-time'
          variant='outlined'
        >
          {ScheduleMethods.everyHours.map((e, i) => (
            <MenuItem key={`everyhour-${i}`} value={e.value}>
              {e.label}
            </MenuItem>
          ))}
        </Select>
        <b className='schedule-add-info'>hours</b>
        <InfoTooltip text='00:00, 02:00, 04:00, ...' />
      </li>
      <li className='schedule-main-row schedule-subrow bold'>or</li>
      <li className='schedule-main-row schedule-subrow'>
        <p className='schedule-row-name bold'>
          Repeat each day at selected hour
        </p>
        <Select
          multiple
          classes={{
            root: 'settings-edit-select',
          }}
          MenuProps={MenuProps}
          name='everyDayHour'
          value={everyDayHour}
          onChange={onChangeDayHour}
          className='schedule-time-box'
          variant='outlined'
        >
          {ScheduleMethods.everyDayHours().map((e, i) => (
            <MenuItem key={`everydayhour-${i}`} value={e.value}>
              {e.label}
            </MenuItem>
          ))}
        </Select>
      </li>
      <li className='schedule-main-row schedule-subrow'>
        <b className='schedule-row-details'>minute</b>
        <Select
          classes={{
            root: 'settings-edit-select',
          }}
          MenuProps={MenuProps}
          name='everyDayMin'
          value={everyDayMin}
          onChange={onChangeDayHour}
          className='schedule-select-time'
          variant='outlined'
        >
          {ScheduleMethods.everyHourMinutes(1).map((e, i) => (
            <MenuItem key={`everydaymin-${i}`} value={e.value}>
              {e.label}
            </MenuItem>
          ))}
        </Select>
        <InfoTooltip text='every day at 11:00' />
      </li>
    </ul>
  );
};

export default EveryHour;
