import { downloadCSVfile } from '../../utils/services';

export const downloadOldLogs = (headers, logs) => {
  const logsData = [];
  logs.forEach((log) => {
    logsData.push(
      headers.map((h) => {
        if (h.key === 'type') {
          return +log.error === 1
            ? 'Error'
            : +log.debug === 1
            ? 'Debug'
            : 'Unknown';
        }
        return log[h.key] ? String(log[h.key]).replace(/,/g, ' ') : '';
      })
    );
  });

  downloadCSVfile({
    headers: headers.map((h) => h.label),
    data: logsData,
    fileName: `old_logs_file_${new Date().getTime()}.csv`
  });
};
