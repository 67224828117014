export const parseSettings = ({ args, schema }) => {
  const settings = {
    values: {},
    headers: {},
    labels: {},
  };

  //const schema = d.attributes['settings-schema'];

  //Form settings
  const defaultHeaders = [
    { key: 'key', label: 'Field Name', editable: false },
    { key: 'value', label: 'Field Value', editable: true },
  ];
  const main_fields = [];

  for (const key in args) {
    if (typeof args[key] === 'object') {
      //work with arrays
      if (Array.isArray(args[key])) {
        if (!args[key].length) continue;
        const headers = [];
        if (Array.isArray(args[key][0])) {
          settings.values[key] = [
            {
              key: 'filter',
              value: JSON.stringify(args[key]),
            },
          ];
          headers.push({
            key: 'key',
            label: 'Filter Name',
            editable: false,
          });
          headers.push({
            key: 'value',
            label: 'Expression',
            editable: true,
          });
        } else if (typeof args[key][0] === 'object') {
          settings.values[key] = args[key];
          for (const keyArr in args[key][0]) {
            headers.push({
              key: keyArr,
              label: keyArr,
              editable: false,
            });
          }
        } else {
          settings.values[key] = args[key];
          headers.push({
            key: 'value',
            label: 'Field Name',
            editable: false,
          });
        }

        //Check headers in schema
        settings.headers[key] = schema[key]
          ? schema[key].headers
            ? schema[key].headers
            : headers
          : headers;
      } else {
        //work with objects
        settings.values[key] = [];
        for (const key1 in args[key]) {
          //work with subobjects 1 level
          if (typeof args[key][key1] === 'object') {
            //arrays 2 level
            if (Array.isArray(args[key][key1])) {
              if (!args[key][key1].length) continue;
              settings.values[key].push({
                key: key1,
                values: args[key][key1],
              });
              const headers = [];
              for (const keyArr in args[key][key1][0]) {
                headers.push({
                  key: keyArr,
                  label: keyArr,
                  editable: false,
                });
              }
              settings.headers[key1] = schema[key1]
                ? schema[key1].headers
                  ? schema[key1].headers
                  : headers
                : headers;
            } else {
              //objects 2 level
              const objArr = [];
              for (const key2 in args[key][key1]) {
                objArr.push({
                  key: key2,
                  value:
                    typeof args[key][key1][key2] === 'object'
                      ? JSON.stringify(args[key][key1][key2])
                      : args[key][key1][key2],
                  label: schema[key2] ? schema[key2].label : '',
                });
              }
              settings.values[key].push({
                key: key1,
                values: objArr,
              });

              settings.headers[key1] = schema[key1]
                ? schema[key1].headers
                  ? schema[key1].headers
                  : defaultHeaders
                : defaultHeaders;
            }
          } else {
            //params of objects 1 level
            settings.values[key].push({
              key: key1,
              value: args[key][key1],
              label: schema[key1] ? schema[key1].label : '',
            });
          }
          if (schema[key1]) {
            settings.labels[key1] = schema[key1];
          }
        }
        settings.headers[key] = schema[key]
          ? schema[key].headers
            ? schema[key].headers
            : defaultHeaders
          : defaultHeaders;
      }
      //Check additional data in schema
      if (schema[key]) {
        settings.labels[key] = schema[key];
      }
    } else {
      main_fields.push({
        key,
        value: args[key],
        label: schema[key] ? schema[key].label : '',
      });
    }
  }

  if (main_fields.length) {
    settings.values = { main_fields, ...settings.values };
    settings.headers = {
      main_fields: defaultHeaders,
      ...settings.headers,
    };
    settings.labels = {
      main_fields: {
        label: 'Main Fields',
      },
      ...settings.labels,
    };
  }
  return settings;
};

export const getConnectorSettings = (values) => {
  const settings = {};
  try {
    for (const key in values) {
      if (key === 'main_fields') {
        values[key].forEach((v) => {
          settings[v.key] = v.value;
        });
      } else if (key === 'filter') {
        values[key].forEach((v) => {
          settings[key] = JSON.parse(v.value);
        });
      } else {
        const dataArr = [];
        const dataObj = {};
        values[key].forEach((level1) => {
          if (level1.key) {
            //Go to level2
            if (level1.values) {
              const dataArr2 = [];
              const dataObj2 = {};
              level1.values.forEach((level2) => {
                if (level2.key && level2.value) {
                  dataObj2[level2.key] = level2.value;
                } else {
                  dataArr2.push(level2);
                }
              });

              if (dataArr2.length) {
                dataObj[level1.key] = dataArr2;
              } else {
                dataObj[level1.key] = dataObj2;
              }
            } else {
              dataObj[level1.key] = level1.value;
            }
          } else {
            dataArr.push(level1);
          }
        });
        if (dataArr.length) {
          settings[key] = dataArr;
        } else {
          settings[key] = dataObj;
        }
      }
    }
  } catch (error) {
    return { error: 'Incorrect filter expression' };
  }

  return settings;
};
