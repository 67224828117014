import { connect } from 'react-redux';
//import { modalTypes } from '../../utils/constants';
import ModalLog from '../modals/ModalLog';
import ModalLogNew from '../modals/ModalLogNew';
import ModalConfirmation from '../modals/ModalConfirmation';
import ModalSchedule from '../modals/ModalSchedule';
import ModalInvite from '../modals/ModalInvite/ModalInvite';

const ConnectorModal = ({ modal: { type } }) => {
  switch (type) {
    case 'log':
      return <ModalLog />;
    case 'logNew':
      return <ModalLogNew />;
    case 'confirmation':
      return <ModalConfirmation />;
    case 'schedule':
      return <ModalSchedule />;
    case 'invite':
      return <ModalInvite />;
    default:
      return <div></div>;
  }
};

const mapStateToProps = (state) => ({
  modal: state.connector.modal
});
export default connect(mapStateToProps)(ConnectorModal);
