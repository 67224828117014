import {
  ADD_PROFILE_USER,
  EDIT_PROFILE_USER,
  DELETE_PROFILE_USER,
  GET_PROFILE_USERS,
  CLEAR_PROFILE,
  GET_PROFILE_ACCOUNTS,
  SET_EMAIL_LOADING,
  UPDATE_ACCOUNT_SETTINGS,
  GET_PROFILE_LOGS,
  SET_PROFILE_LOGS_LOADING,
  GET_ORDERS_ERRORS,
  SET_ORDERS_ERRORS_LOADING,
} from '../actions/types';

const initialState = {
  users: [],
  accounts: [],
  logs: [],
  ordersErrors: [],
  loadingOrdersErrors: true,
  loadingLogs: true,
  loadingAccounts: true,
  loadingEmail: false,
  loading: true,
};

export default function profile(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_PROFILE_USERS:
      return {
        ...state,
        users: payload,
        loading: false,
      };
    case ADD_PROFILE_USER:
      return {
        ...state,
        users: payload ? payload : state.users,
      };
    case EDIT_PROFILE_USER:
      return {
        ...state,
        users: payload
          ? state.users.map((user) =>
              user._id === payload._id ? payload : user
            )
          : state.users,
      };
    case DELETE_PROFILE_USER:
      return {
        ...state,
        users: state.users.filter((u) => u._id !== payload),
      };
    case GET_PROFILE_ACCOUNTS:
      return {
        ...state,
        accounts: payload,
        loadingAccounts: false,
      };
    case UPDATE_ACCOUNT_SETTINGS:
      return {
        ...state,
        accounts: state.accounts.map((acc) => {
          if (acc.id === payload.id) {
            acc.settings.values = payload.values.settings;
          }
          return acc;
        }),
      };
    case SET_EMAIL_LOADING:
      return { ...state, loadingEmail: payload };
    case GET_PROFILE_LOGS:
      return { ...state, logs: payload, loadingLogs: false };
    case SET_PROFILE_LOGS_LOADING:
      return { ...state, loadingLogs: true };
    case GET_ORDERS_ERRORS:
      return { ...state, ordersErrors: payload, loadingOrdersErrors: false };
    case SET_ORDERS_ERRORS_LOADING:
      return { ...state, loadingOrdersErrors: true };
    case CLEAR_PROFILE:
      return initialState;
    default:
      return state;
  }
}
