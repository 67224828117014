import Breadcrumbs from '../../components/layout/Breadcrumbs';
import classes from './PageContainer.module.css';

export const PageContainer = ({
  breadPath,
  breadComponent,
  breadLoading,
  children
}) => {
  return (
    <>
      <Breadcrumbs
        path={breadPath}
        component={breadComponent}
        loading={breadLoading}
      />
      <section className={classes.container}>{children}</section>
    </>
  );
};
