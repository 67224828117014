import {
  GET_CONNECTIONS,
  CLEAR_CONNECTIONS,
  SET_CONNECTIONS_LOADING
} from '../actions/types';

const initialState = {
  actionGroups: [],
  actionGroupsNew: [],
  groupsNew: [],
  feedsList: [],
  loading: true
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CONNECTIONS:
      return {
        ...state,
        ...payload,
        loading: false
      };
    case SET_CONNECTIONS_LOADING:
      return {
        ...state,
        loading: true
      };
    case CLEAR_CONNECTIONS:
      return initialState;

    default:
      return state;
  }
}
