import React, { useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { PageLoader } from '../../utils/components';
import { updateAction } from '../../actions/connector';
//import { formatActionDate, calcDate } from '../../utils/constants';
import ActionLogsNew from './components/ActionLogsNew';
import ActionDescription from './components/ActionDescription';
import AccountSettings from '../../components/settings/connections/AccountSettings';

import { Settings, ListAlt } from '@material-ui/icons';
import { ImInfo } from 'react-icons/im';
import { useLocation } from 'react-router-dom';
import { formActionTabUrl } from '../../utils/services';
import ActionHeaderNew from './components/ActionHeaderNew';
import { PageContainer } from '../../widgets/PageContainer/PageContainer';

const ActionNew = ({
  connector: { actionsNew, loadingNew },
  groups,
  user: { role },
  updateAction,
  tabActive = 0
}) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const action = useMemo(
    () => actionsNew.find((a) => +a.id === +id),
    [actionsNew, id]
  );
  const path = useMemo(() => {
    let res = [
      { name: 'My applications', link: '/dashboard/' },
      { name: 'Action Center (New)', link: '/actionsnew/' }
    ];

    if (action?.groups) {
      res = [
        ...res,
        ...action.groups
          .map((a) => {
            const group = groups.find((g) => +g.id === +a);
            const parentId = group?.relationships?.parent?.data?.id;
            const rootGroup = groups.find((g) => +g.id === +parentId);
            const rootId = rootGroup?.relationships?.parent?.data?.id;
            let groupNumber = a;
            if (action.groups.includes(+rootId)) {
              groupNumber = rootId;
            } else if (action.groups.includes(+parentId)) {
              groupNumber = parentId;
            }
            return {
              name: group ? group.name : '',
              link: action ? `/actionsnew/${groupNumber}/` : '',
              action: 'setActionGroup',
              actionData: a,
              isHidden: group ? group['is-hidden'] : false
            };
          })
          .filter((p) => !p.isHidden)
      ];
    }
    res.push({ name: action ? action.name : '' });
    return res;
  }, [action, groups]);

  return (
    <PageContainer
      breadComponent={
        <Link
          to={
            action
              ? action.groups.length
                ? `/settings/add/action/?connection=${action.groups[0]}`
                : '/settings/add/action/'
              : '/settings/add/action/'
          }
          className="breadcrumbs-add-btn"
        >
          + Add new action
        </Link>
      }
      breadPath={path}
      breadLoading={loadingNew}
    >
      {loadingNew ? (
        <PageLoader height="70vh" />
      ) : (
        <>
          <ActionHeaderNew actionData={action} />
          <div className="action-tabs-container">
            <Link
              className={`action-tab-container  ${
                tabActive === 0 ? 'action-tab-active' : ''
              }`}
              to={`${formActionTabUrl(pathname)}logs/`}
            >
              <ListAlt fontSize="small" />
              <span className="icon-name-text">Logs</span>
            </Link>
            <Link
              className={`action-tab-container  ${
                tabActive === 1 ? 'action-tab-active' : ''
              }`}
              to={`${formActionTabUrl(pathname)}settings/`}
            >
              <Settings fontSize="small" />
              <span className="icon-name-text">Settings</span>
            </Link>
            <Link
              className={`action-tab-container  ${
                tabActive === 2 ? 'action-tab-active' : ''
              }`}
              to={`${formActionTabUrl(pathname)}description/`}
            >
              <ImInfo className="action-tab-icon" />
              <span className="icon-name-text">Description</span>
            </Link>
          </div>
          <div>
            <div className={tabActive === 0 ? 'active-tab' : 'disabled-tab'}>
              <ActionLogsNew actionData={action ? action : { id: '' }} />
            </div>
            <div className={tabActive === 1 ? 'active-tab' : 'disabled-tab'}>
              <AccountSettings
                data={action ? action : {}}
                action={updateAction}
                record="Action"
              />
            </div>
            <div className={tabActive === 2 ? 'active-tab' : 'disabled-tab'}>
              <ActionDescription
                description={action ? action.description : ''}
              />
            </div>
          </div>
        </>
      )}
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  connector: state.connector,
  user: state.auth.user,
  groups: state.connections.groupsNew
});
export default connect(mapStateToProps, {
  updateAction
})(ActionNew);
