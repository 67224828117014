import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setActionGroup } from '../../actions/connector';

const BreadcrumbsPath = ({ path, setActionGroup }) => (
  <div className='breadcrumbs-box'>
    {path
      ? path.map((p, i) => (
          <div key={`path-key-${i}`} className='breadcrumbs-item'>
            {p.link ? (
              <Link
                to={p.link}
                onClick={() => {
                  if (p.action === 'setActionGroup')
                    setActionGroup(p.actionData);
                }}
                className='breadcrumbs-link'
              >
                {p.name}
              </Link>
            ) : (
              <span className='breadcrumbs-end-name'>{p.name}</span>
            )}
            {i !== path.length - 1 ? (
              <span className='breadcrumbs-separator'>/</span>
            ) : null}
          </div>
        ))
      : null}
  </div>
);

const mapStateToProps = (state) => ({
  connector: state.connector,
});

export default connect(mapStateToProps, {
  setActionGroup,
})(BreadcrumbsPath);
