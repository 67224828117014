import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import connector from './connector';
import connections from './connections';
import profile from './profile';
import publicReducer from './publicReducer';
import sharedAccess from './sharedAccess';

export default combineReducers({
  alert,
  auth,
  connector,
  connections,
  profile,
  publicReducer,
  sharedAccess
});
