import {
  GET_ACTIONS,
  CLEAR_CONNECTOR,
  OPEN_CONNECTOR_MODAL,
  CLOSE_CONNECTOR_MODAL,
  GET_ACTION_LOGS,
  GET_ACTION_SETTINGS,
  RUN_ACTION,
  SET_RUN_ACTION_LOADING,
  SET_ACTION_LOGS_LOADING,
  SET_ACTION_SETTINGS_LOADING,
  GET_ACTIONS_NEW,
  GET_ALL_FEEDS,
  GET_FEED_ITEMS,
  SET_LOADING_FEED_ITEMS,
  SET_LOADING_SEND_ITEMS,
  SET_LOADING_IDENTIFY_ITEMS,
  SET_SELECTED_ITEMS,
  SET_ACTION_LOGS_NEW_LOADING,
  GET_ACTION_LOGS_NEW,
  SELECT_ACTION_GROUP,
  RUN_ACTION_NEW,
  UPDATE_ACTION_SETTINGS,
  SET_LOADING_SCHEDULE,
  UPDATE_ACTION_SCHEDULE,
  GET_ACTIONS_UPDATES,
  STOP_ACTION,
  SET_STOP_ACTION_LOADING,
  SET_LOADING_LOGS_FILE
} from '../actions/types';

const initialState = {
  actions: [],
  actionsNew: [],
  actionsSetRun: [],
  actionsSetStop: [],
  selectedGroup: null,
  actionSettings: {},
  actionLogs: [],
  actionLogsNew: [],
  actionLogsPages: 0,
  modal: {
    id: null,
    type: null,
    isOpen: false,
    data: null,
    dataLoading: true
  },
  actionRun: {
    id: null,
    loading: false
  },
  feeds: {
    list: [],
    items: [],
    totalItems: null,
    brands: [],
    selected: [],
    feedItemsLoading: true,
    feedsListLoading: true,
    feedSendItemsLoading: false,
    feedIdentifyLoading: false
  },
  loading: true,
  loadingNew: true,
  settingsLoading: true,
  logsLoading: true,
  logsLoadingNew: true,
  loadingSchedule: false,
  loadingLogsFile: false
};

export default function connector(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ACTIONS:
      return {
        ...state,
        actions: payload,
        loading: false
      };
    case GET_ACTIONS_NEW:
      return {
        ...state,
        actionsNew: payload,
        actionsSetRun: [],
        actionsSetStop: [],
        loadingNew: false
      };
    case UPDATE_ACTION_SCHEDULE:
      return {
        ...state,
        actionsNew: state.actionsNew.map((action) => {
          if (+action.id === +payload.id) {
            if (payload.schedule !== undefined)
              action.schedule = payload.schedule;
            if (payload.status !== undefined)
              action['schedule-status'] = payload.status;
          }
          return action;
        }),
        loadingSchedule: false
      };
    case GET_ACTIONS_UPDATES:
      return {
        ...state,
        actionsNew: state.actionsNew.map((action) => {
          const update = payload.find((u) => u.id === action.id);
          if (update) {
            action = { ...action, ...update };
          }
          return action;
        })
      };
    case STOP_ACTION:
      return {
        ...state,
        actionsNew: state.actionsNew.map((action) => {
          if (+action.id === +payload.actionId) {
            action['schedule-status'] = payload.status;
          }
          return action;
        })
      };
    case OPEN_CONNECTOR_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          ...payload,
          isOpen: true
        }
      };
    case CLOSE_CONNECTOR_MODAL:
      return {
        ...state,
        modal: {
          id: null,
          type: null,
          isOpen: false,
          data: null,
          dataLoading: true
        }
      };
    case SELECT_ACTION_GROUP:
      return {
        ...state,
        selectedGroup: payload
      };
    case GET_ACTION_LOGS:
      return {
        ...state,
        actionLogs: payload,
        logsLoading: false
      };
    case GET_ACTION_LOGS_NEW:
      return {
        ...state,
        ...payload,
        logsLoadingNew: false
      };
    case SET_ACTION_LOGS_LOADING:
      return {
        ...state,
        logsLoading: true
      };
    case SET_ACTION_LOGS_NEW_LOADING:
      return {
        ...state,
        logsLoadingNew: true
      };
    case GET_ACTION_SETTINGS:
      return {
        ...state,
        actionSettings: payload,
        settingsLoading: false
      };
    case SET_ACTION_SETTINGS_LOADING:
      return {
        ...state,
        settingsLoading: true
      };
    case SET_LOADING_SCHEDULE:
      return {
        ...state,
        loadingSchedule: true
      };
    case CLEAR_CONNECTOR:
      return initialState;
    case RUN_ACTION:
      return {
        ...state,
        actions: state.actions.map((action) => {
          if (action.id === payload.id) action.status = payload.status;
          return action;
        })
      };
    case RUN_ACTION_NEW:
      return {
        ...state,
        actionsNew: state.actionsNew.map((action) => {
          if (action.id === payload.id) {
            action.status = payload.status;
            action['last-task-id'] = payload.taskId;
          }
          return action;
        })
      };
    case SET_RUN_ACTION_LOADING:
      return {
        ...state,
        actionsSetRun: [...state.actionsSetRun, payload]
      };
    case SET_STOP_ACTION_LOADING:
      return {
        ...state,
        actionsSetStop: [...state.actionsSetStop, payload]
      };
    case GET_ALL_FEEDS:
      return {
        ...state,
        feeds: {
          ...state.feeds,
          list: payload,
          feedsListLoading: false
        }
      };
    case GET_FEED_ITEMS:
      return {
        ...state,
        feeds: {
          ...state.feeds,
          ...payload,
          feedItemsLoading: false
        }
      };
    case SET_LOADING_FEED_ITEMS:
      return {
        ...state,
        feeds: {
          ...state.feeds,
          feedItemsLoading: true
        }
      };
    case SET_LOADING_SEND_ITEMS:
      return {
        ...state,
        feeds: {
          ...state.feeds,
          feedSendItemsLoading: payload
        }
      };
    case SET_LOADING_LOGS_FILE:
      return {
        ...state,
        loadingLogsFile: payload
      };
    case SET_LOADING_IDENTIFY_ITEMS:
      return {
        ...state,
        feeds: {
          ...state.feeds,
          feedIdentifyLoading: payload
        }
      };
    case SET_SELECTED_ITEMS:
      return {
        ...state,
        feeds: {
          ...state.feeds,
          selected: payload
        }
      };
    case UPDATE_ACTION_SETTINGS:
      return {
        ...state,
        actionsNew: state.actionsNew.map((acc) => {
          if (acc.id === payload.id) {
            acc.settings.values = payload.values.settings;
          }
          return acc;
        })
      };
    default:
      return state;
  }
}
