import { useState, useEffect, useRef, useCallback } from 'react';
import classes from './Select.module.css';
import { MdKeyboardArrowDown } from 'react-icons/md';

export const Select = ({
  onChange,
  value,
  options,
  containerClass,
  multiple,
  labelKey,
  valueKey
}) => {
  const [isOpen, setOpen] = useState(false);
  const selectArea = useRef(null);
  const selectList = useRef(null);

  const setSelectedCss = (option) => {
    let className = '';
    if (value) {
      if (multiple) {
        if (value.some((s) => s[valueKey] === option[valueKey])) {
          className = classes.filterSelectLineActive;
        }
      } else if (option[valueKey] === value[valueKey]) {
        className = classes.filterSelectLineActive;
      }
    }
    return className;
  };

  const changeOption = (option) => {
    if (option) {
      let newValue = options.find((o) => o[valueKey] === option);
      if (multiple && newValue) {
        const oldValue = value ? value : [];
        if (oldValue.some((v) => v[valueKey] === newValue[valueKey])) {
          newValue = oldValue.filter((o) => o[valueKey] !== newValue[valueKey]);
        } else {
          newValue = [...oldValue, newValue];
        }
      }
      onChange(newValue);
      setOpen(false);
    }
  };

  const openList = () => {
    setOpen(!isOpen);
  };

  const checkSelect = useCallback(
    (e) => {
      if (!isOpen || !selectArea.current || !selectList.current) return;
      if (
        !selectArea.current.contains(e.target) &&
        !selectList.current.contains(e.target)
      ) {
        setOpen(false);
      }
    },
    [isOpen, setOpen, selectArea, selectList]
  );

  //Control closing Select results
  useEffect(() => {
    window.addEventListener('click', checkSelect);
    return () => {
      window.removeEventListener('click', checkSelect);
    };
  }, [checkSelect]);

  return (
    <div className={`${classes.selectBox} ${containerClass}`}>
      <div
        ref={selectArea}
        className={classes.filterSelectBox}
        onClick={openList}
      >
        <div className={classes.filterSelectValue}>
          {value
            ? multiple
              ? value.map((s) => s[labelKey]).join('; ')
              : value[labelKey]
            : null}
        </div>
        <div className={classes.selectIconBox}>
          <MdKeyboardArrowDown
            className={`${classes.filterSelectArrow} ${
              isOpen ? classes.filterSelectArrowUp : ''
            }`}
          />
        </div>
      </div>
      <ul
        ref={selectList}
        className={`${classes.filterSelectList} ${
          isOpen ? classes.filterSelectListActive : ''
        }`}
      >
        {options.map((option, i) => (
          <li
            key={`option-${option[labelKey]}-${i}`}
            onClick={() => changeOption(option[valueKey])}
            className={`${classes.filterSelectLine} ${setSelectedCss(option)}`}
          >
            {option[labelKey]}
          </li>
        ))}
      </ul>
    </div>
  );
};
