export const actionHeaders = [
  { label: 'ID', key: 'id', width: '80px' },
  { label: 'STATUS', key: 'status', width: '120px' },
  {
    label: 'LAST RUN',
    key: 'run-at',
    text: 'Date and Time of the last action run in the time zone selected in the User settings (by default - Your local time)',
    width: '180px'
  },
  {
    label: 'NEXT RUN',
    key: 'next-run-at',
    text: 'Date and Time of the next action run according to the settled schedule in the time zone selected in the User settings (by default - Your local time)',
    width: '180px'
  },
  {
    label: 'RUN',
    key: 'run',
    text: 'Here you can force action to run manually. Just click on the icon next to the required action',
    width: '100px'
  },
  { label: 'SCHEDULE', key: 'schedule', width: '100px' }
];

export const actionHeadersOld = [
  { label: 'ID', key: 'id', width: '80px' },
  { label: 'STATUS', key: 'status', width: '120px' },
  {
    label: 'LAST RUN',
    key: 'last_run',
    text: 'Date and Time of the last action run in connector time (CST/CDT)',
    width: '180px'
  },
  {
    label: 'NEXT RUN',
    key: 'next_run',
    text: 'Date and Time of the next action run according to the settled schedule in connector time (CST/CDT)',
    width: '180px'
  },
  {
    label: 'RUN',
    key: 'run',
    text: 'Here you can force action to run manually. Just click on the icon next to the required action',
    width: '100px'
  }
];
