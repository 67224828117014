import { useState, useEffect, useMemo } from 'react';
import ScheduleMethods from './ScheduleMethods';
import { MenuItem, Select } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { getTimezoneHours } from '../../../utils/constants';

const Daily = ({ timezone, setCron, defaultCron }) => {
  const [formData, setFormData] = useState({
    weekDay: ScheduleMethods.getCronDay(defaultCron),
    time: ScheduleMethods.getCronTime(defaultCron, getTimezoneHours(timezone)),
  });

  const { weekDay, time } = formData;

  const onChangeForm = (e) => {
    if (e.target) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      setFormData({ ...formData, time: e });
    }
  };

  useEffect(() => {
    if (weekDay && time) {
      setCron(
        ScheduleMethods.calculateDailyCron({
          time,
          day: weekDay,
          timezone: getTimezoneHours(timezone),
        })
      );
    }
  }, [timezone, time, weekDay, setCron]);

  const MenuProps = useMemo(
    () => ({
      classes: { paper: 'select-list' },
      variant: 'menu',
      getContentAnchorEl: null,
    }),
    []
  );

  return (
    <ul>
      <li className='schedule-main-row'>
        <p className='schedule-row-name bold'>Repeat every</p>
        <Select
          classes={{
            root: 'settings-edit-select',
          }}
          MenuProps={MenuProps}
          name='weekDay'
          value={weekDay}
          onChange={onChangeForm}
          className='schedule-select-time'
          variant='outlined'
        >
          {ScheduleMethods.monthDays.map((e, i) => (
            <MenuItem key={`everyday-${i}`} value={e.value}>
              {e.label}
            </MenuItem>
          ))}
        </Select>
        <b className='schedule-add-info'>days</b>
      </li>
      <li className='schedule-main-row schedule-subrow'>
        <p className='schedule-row-name bold'>Time</p>
        <div className='schedule-time-box'>
          <DatePicker
            selected={time}
            onChange={onChangeForm}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={1}
            timeCaption='Time'
            dateFormat='HH:mm'
            timeFormat='HH:mm'
            className='schedule-time-picker'
          />
        </div>
        <AiOutlineClockCircle className='schedule-time-icon' />
      </li>
    </ul>
  );
};

export default Daily;
