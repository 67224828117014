import { useRef, useEffect, memo } from 'react';

export const ActionLogMessage = memo(({ message, className, isShort }) => {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      let msg = message ? message : '';
      if (isShort) msg = msg.length > 300 ? `${msg.substring(0, 300)}...` : msg;
      ref.current.innerHTML = msg;
    }
  }, [message, isShort]);
  return <div className={className ? className : ''} ref={ref}></div>;
});
