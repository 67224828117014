import { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  closeConnectorModal,
  updateActionSchedule
} from '../../actions/connector';
import { Close } from '@material-ui/icons';
import {
  timezones,
  roles,
  dbLogTypes,
  getDbLogMessage
} from '../../utils/constants';
import ScheduleMethods from './schedule/ScheduleMethods';
import { MenuItem, Select } from '@material-ui/core';
import ScheduleMain from './schedule/ScheduleMain';
import { InfoTooltip } from '../../utils/components';

const ModalSchedule = ({
  modal: { data, isOpen },
  connector: { loadingSchedule },
  groups,
  user,
  closeConnectorModal,
  updateActionSchedule
}) => {
  const groupName = useMemo(() => {
    let group = data?.groups ? data?.groups[0] : null;
    group = groups.find((g) => +g.id === +group);
    return group?.name;
  }, [data, groups]);

  const [formData, setFormData] = useState({
    timezone: user.timezone ? user.timezone : 'UTC',
    scheduleType: data.schedule
      ? ScheduleMethods.getCronType(data.schedule)
      : ''
  });
  const [cron, setCron] = useState(data.schedule ? data.schedule : '');

  const { timezone, scheduleType } = formData;

  const onChangeForm = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const checkIfModal = (e) => {
    if (e.target.classList) {
      if (e.target.classList.contains('modal')) {
        closeConnectorModal();
      }
    }
  };

  const closeModal = () => {
    closeConnectorModal();
  };

  const changeSchedule = async () => {
    const res = await updateActionSchedule({
      id: data.id,
      schedule: cron,
      status: true,
      logData: {
        actionId: +data.id,
        type: dbLogTypes.action,
        message: getDbLogMessage(
          'UPDATE Action Schedule',
          data.name,
          groupName
        ),
        isNewConnector: true
      }
    });
    if (res) closeConnectorModal();
  };

  const removeSchedule = async () => {
    if (window.confirm('Are you sure to remove schedule?')) {
      const res = await updateActionSchedule({
        id: data.id,
        schedule: '',
        status: false,
        logData: {
          actionId: +data.id,
          type: dbLogTypes.action,
          message: getDbLogMessage(
            'REMOVE Action Schedule',
            data.name,
            groupName
          ),
          isNewConnector: true
        }
      });
      if (res) closeConnectorModal();
    }
  };

  const disableSchedule = async () => {
    const res = await updateActionSchedule({
      id: data.id,
      status: false,
      logData: {
        actionId: +data.id,
        type: dbLogTypes.action,
        message: getDbLogMessage(
          'DISABLE Action Schedule',
          data.name,
          groupName
        ),
        isNewConnector: true
      }
    });
    if (res) closeConnectorModal();
  };

  const enableSchedule = async () => {
    const res = await updateActionSchedule({
      id: data.id,
      status: true,
      logData: {
        actionId: +data.id,
        type: dbLogTypes.action,
        message: getDbLogMessage(
          'ENABLE Action Schedule',
          data.name,
          groupName
        ),
        isNewConnector: true
      }
    });
    if (res) closeConnectorModal();
  };

  return (
    <div
      className="modal"
      onClick={checkIfModal}
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className="modal-container modal-schedule-container">
        <div className="modal-header">
          <h1 className="modal-title">Action Schedule</h1>
          <Close
            onClick={closeModal}
            className="actions-icon"
            fontSize="medium"
          />
        </div>
        <div className="modal-body schedule-container">
          <div className="schedule-box">
            <ul className="schedule-main-list">
              <li className="schedule-main-row">
                <p className="schedule-row-name bold">Action Name</p>
                <p className="schedule-row-action-name">{data.name}</p>
              </li>
              <li className="schedule-main-row">
                <p className="schedule-row-name bold">Time Zone</p>
                <Select
                  classes={{
                    root: 'settings-edit-select'
                  }}
                  MenuProps={{
                    classes: { paper: 'select-list' }
                  }}
                  name="timezone"
                  value={timezone}
                  onChange={onChangeForm}
                  className="settings-select-box"
                  variant="outlined"
                >
                  {timezones.map((zone, i) => (
                    <MenuItem key={`timezone-${i}`} value={zone.value}>
                      {zone.label}
                    </MenuItem>
                  ))}
                </Select>
              </li>
              <li className="schedule-main-row">
                <p className="schedule-row-name bold">Frequency</p>
                <Select
                  classes={{
                    root: 'settings-edit-select'
                  }}
                  MenuProps={{
                    classes: { paper: 'select-list' }
                  }}
                  name="scheduleType"
                  value={scheduleType}
                  onChange={onChangeForm}
                  className="settings-select-box"
                  variant="outlined"
                >
                  {ScheduleMethods.scheduleTypes.map((type, i) => (
                    <MenuItem key={`timezone-${i}`} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </li>
            </ul>
            <div className="schedule-sublist-container">
              <ScheduleMain
                type={scheduleType}
                timezone={timezone}
                setCron={setCron}
                defaultCron={data.schedule ? data.schedule.split(' ') : []}
              />
            </div>
            <p>
              CRON time format: <b>{cron}</b>
              <InfoTooltip text="UTC Time" />
            </p>
            <p className="schedule-cron-info">
              You can find more details on the selected cron format{' '}
              <a
                className="schedule-cron-link"
                href="https://crontab.guru/"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </p>
            {user.role === roles.admin.name ? (
              <div className="schedule-btn-box">
                <button
                  className="settings-btn settings-update-btn"
                  disabled={cron ? (loadingSchedule ? true : false) : true}
                  onClick={changeSchedule}
                >
                  Save
                </button>
                {data['schedule-status'] ? (
                  <button
                    className="settings-btn settings-disable-btn settings-delete-schedule"
                    disabled={
                      data.schedule ? (loadingSchedule ? true : false) : true
                    }
                    onClick={disableSchedule}
                  >
                    Disable Schedule
                  </button>
                ) : (
                  <button
                    className="settings-btn settings-disable-btn settings-delete-schedule"
                    disabled={
                      data.schedule ? (loadingSchedule ? true : false) : true
                    }
                    onClick={enableSchedule}
                  >
                    Enable Schedule
                  </button>
                )}
                <button
                  className="settings-btn settings-delete-btn settings-delete-schedule"
                  disabled={
                    data.schedule ? (loadingSchedule ? true : false) : true
                  }
                  onClick={removeSchedule}
                >
                  Remove Schedule
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  modal: state.connector.modal,
  user: state.auth.user,
  connector: state.connector,
  groups: state.connections.groupsNew
});

export default connect(mapStateToProps, {
  closeConnectorModal,
  updateActionSchedule
})(ModalSchedule);
