import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import { clearConnector } from './connector';
import { clearConnections } from './connections';
import { clearProfile } from './profile';
import { clearSharedAccess } from './sharedAccess';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_AUTH_LOADING,
  SET_URL,
  EDIT_USER,
  UPDATE_PROFILE,
  START_TRACKING,
  ADD_PORTLET,
  REMOVE_PORTLET,
  CHANGE_PORTLET,
  ADD_FAVOURITE_ACTION,
  REMOVE_FAVOURITE_ACTION
} from './types';
import { actionErrorHandler } from '../utils/services';

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};
//Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    dispatch({
      type: SET_AUTH_LOADING
    });

    const res = await axios.get('/api/auth');

    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL
    });
  }
};

//Register New Profile
export const createProfile =
  ({
    name,
    email,
    password,
    company,
    connectAddress,
    connectUser,
    connectPassword
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_AUTH_LOADING
      });

      const body = JSON.stringify({
        company: company,
        connector: {
          ip: connectAddress,
          user: connectUser,
          password: connectPassword
        },
        admin: {
          name: name,
          email: email,
          password: password
        }
      });

      const res = await axios.post('/api/profile', body, config);

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data
      });
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: REGISTER_FAIL
      });
    }
  };

//Login User
export const login = (email, password) => async (dispatch) => {
  const body = JSON.stringify({ email, password });
  try {
    dispatch({
      type: SET_AUTH_LOADING
    });

    const res = await axios.post('/api/auth', body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
    dispatch({
      type: LOGIN_FAIL
    });
  }
};

//Login User
export const loginWithGoogle = (token) => async (dispatch) => {
  const body = JSON.stringify({ token });
  try {
    dispatch({
      type: SET_AUTH_LOADING
    });

    const res = await axios.post('/api/auth/google', body, config);
    if (res.data?.token) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { token: res.data.token }
      });
    } else {
      dispatch(setAlert('Invalid credentials', 'error'));
      dispatch({
        type: LOGIN_FAIL
      });
    }

    if (window.google && res.data?.email) {
      window.google.accounts.id.revoke(res.data?.email);
    }
  } catch (err) {
    actionErrorHandler(err, dispatch, setAlert);
    dispatch({
      type: LOGIN_FAIL
    });
  }
};

// Change Profile User
export const updateUser = (data) => async (dispatch) => {
  try {
    const body = JSON.stringify(data);
    const res = await axios.put('/api/users/profile', body, config);

    dispatch({
      type: EDIT_USER,
      payload: res.data
    });
    dispatch(setAlert('Profile User changed successfully!', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: EDIT_USER,
      payload: {}
    });
  }
};

// Change Profile User
export const updateProfile = (data) => async (dispatch) => {
  try {
    const body = JSON.stringify(data);
    const res = await axios.put(`/api/profile`, body, config);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });
    dispatch(
      setAlert('Profile Connector Settings changed successfully!', 'success')
    );
    return res.data;
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    } else {
      dispatch(
        setAlert(
          'Unexpected error. Try to update connector settings later',
          'error'
        )
      );
    }
    dispatch({
      type: UPDATE_PROFILE,
      payload: {}
    });
    return null;
  }
};

//Add Portlet to the user
export const addPortlet = (body) => async (dispatch) => {
  try {
    const res = await axios.post('/api/portlet', JSON.stringify(body), config);

    dispatch({
      type: ADD_PORTLET,
      payload: body.portlets ? [res.data, ...body.portlets] : [res.data]
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
  }
};

//Change User Portlet
export const changePortlet = (portlets) => async (dispatch) => {
  try {
    await axios.put('/api/portlet', JSON.stringify({ portlets }), config);

    dispatch({
      type: CHANGE_PORTLET,
      payload: portlets
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
  }
};

//Remove User Portlet
export const removePortlet =
  ({ id, portlets }) =>
  async (dispatch) => {
    try {
      await axios.delete(`/api/portlet/${id}`, { data: { portlets } });

      dispatch({
        type: REMOVE_PORTLET,
        payload: portlets ? portlets : []
      });
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    }
  };

//Add Favourite Action to the user
export const addFavouriteAction = (body) => async (dispatch) => {
  try {
    const res = await axios.post(
      '/api/favouriteAction',
      JSON.stringify(body),
      config
    );

    dispatch({
      type: ADD_FAVOURITE_ACTION,
      payload: res.data
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
  }
};

//Remove user's Favourite Action
export const removeFavouriteAction = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/favouriteAction/${id}`);

    dispatch({
      type: REMOVE_FAVOURITE_ACTION,
      payload: id
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
  }
};

//Switch Role or Account
export const switchRoleAccount = (data) => async (dispatch) => {
  try {
    let queries = '';

    for (let key in data) {
      if (data[key]) {
        queries += `${!queries ? '?' : '&'}${key}=${data[key]}`;
      }
    }
    const res = await axios.get(`/api/auth/switch${queries}`);

    if (res?.data?.token) {
      localStorage.setItem('token', res.data.token);
    }
    return res.data;
  } catch (err) {
    actionErrorHandler(err, dispatch, setAlert);
  }
  return null;
};

//Log Out / Clear
export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT
  });
  dispatch(clearConnections());
  dispatch(clearConnector());
  dispatch(clearProfile());
  dispatch(clearSharedAccess());
};

//Set direct URL
export const setURL = (url) => (dispatch) => {
  dispatch({
    type: SET_URL,
    payload: url
  });
};

export const startTracking = () => (dispatch) => {
  dispatch({
    type: START_TRACKING
  });
};
