import axios from 'axios';
import { setAlert } from './alert';
import {
  SET_PUBLIC_EMAIL_LOADING,
  GET_CAPTCHA_SCORE,
  CHECK_RESET_TOKEN
} from './types';

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

//Check reCaptcha score
export const checkScore = (token) => async (dispatch) => {
  try {
    const body = JSON.stringify({ token });
    const res = await axios.post('/api/public/score', body, config);

    dispatch({ type: GET_CAPTCHA_SCORE, payload: res.data });
    if (res.data < 0.5)
      dispatch(
        setAlert('High risk activity detected. Try again later', 'error', 10000)
      );
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
    dispatch({ type: GET_CAPTCHA_SCORE, payload: 1 });
  }
};

//Check Reset Password Token
export const checkResetToken = (token) => async (dispatch) => {
  try {
    const res = await axios.get('/api/public/resetpwd', {
      headers: {
        'x-auth-token': token
      }
    });

    dispatch({
      type: CHECK_RESET_TOKEN,
      payload: res.data
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
    dispatch({
      type: CHECK_RESET_TOKEN,
      payload: null
    });
  }
};

export const sendRecoveryToken = (email) => async (dispatch) => {
  const body = JSON.stringify({ email });
  try {
    dispatch({
      type: SET_PUBLIC_EMAIL_LOADING,
      payload: true
    });

    await axios.post('/api/public/resetpwd', body, config);

    dispatch({
      type: SET_PUBLIC_EMAIL_LOADING,
      payload: false
    });
    return true;
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    }
  }

  dispatch({
    type: SET_PUBLIC_EMAIL_LOADING,
    payload: false
  });
  return false;
};

export const changePassword = (password, token) => async (dispatch) => {
  const body = JSON.stringify({ password });
  try {
    dispatch({
      type: SET_PUBLIC_EMAIL_LOADING,
      payload: true
    });

    await axios.put('/api/public/user', body, {
      headers: { 'x-auth-token': token, 'Content-Type': 'application/json' }
    });

    dispatch({
      type: SET_PUBLIC_EMAIL_LOADING,
      payload: false
    });
    return true;
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    }
  }

  dispatch({
    type: SET_PUBLIC_EMAIL_LOADING,
    payload: false
  });
  return false;
};
