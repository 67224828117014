import React from 'react';
import classes from './Input.module.css';

export const InputTypes = {
  LOGIN: classes.inputLogin
};

export const Input = ({
  className = '',
  value,
  onChange,
  error,
  inputType = '',
  hideError,
  ...otherProps
}) => {
  return (
    <div className={className}>
      <input
        className={`${classes.input} ${inputType} ${error || ''}`}
        value={value}
        autoComplete="off"
        onChange={onChange}
        {...otherProps}
      />
      {!hideError && <p className={classes.error}>{error}</p>}
    </div>
  );
};
