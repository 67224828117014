import AlertBlock from './components/layout/AlertBlock';
import setAuthToken from './utils/setAuthToken';
import AppRoutes from './components/routing/AppRoutes';
import EventHandler from './components/modules/EventHandler/EventHandler';
import { Provider } from 'react-redux';
import store from './store';

import './css/Main.css';
import './css/Dashboard.css';
import './css/Actions.css';
import './css/Feeds.css';
import './css/Modal.css';
import './css/Settings.css';
import './css/Profile.css';
import 'react-datepicker/dist/react-datepicker.css';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  return (
    <Provider store={store}>
      <AppRoutes />
      <AlertBlock />
      <EventHandler />
    </Provider>
  );
};

export default App;
