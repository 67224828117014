import axios from 'axios';
import { setAlert } from './alert';
import { parseSettings, getConnectorSettings } from '../utils/parsers';
import { serverUrl } from '../utils/constants';
import {
  GET_ACTIONS,
  CLEAR_CONNECTOR,
  OPEN_CONNECTOR_MODAL,
  CLOSE_CONNECTOR_MODAL,
  GET_ACTION_LOGS,
  GET_ACTION_SETTINGS,
  RUN_ACTION,
  SET_RUN_ACTION_LOADING,
  SET_ACTION_LOGS_LOADING,
  SET_ACTION_SETTINGS_LOADING,
  GET_ACTIONS_NEW,
  GET_ALL_FEEDS,
  GET_FEED_ITEMS,
  SET_LOADING_FEED_ITEMS,
  SET_LOADING_SEND_ITEMS,
  SET_LOADING_IDENTIFY_ITEMS,
  SET_SELECTED_ITEMS,
  SET_ACTION_LOGS_NEW_LOADING,
  GET_ACTION_LOGS_NEW,
  SELECT_ACTION_GROUP,
  RUN_ACTION_NEW,
  UPDATE_ACTION_SETTINGS,
  UPDATE_ACTION_SCHEDULE,
  SET_LOADING_SCHEDULE,
  GET_ACTIONS_UPDATES,
  STOP_ACTION,
  SET_STOP_ACTION_LOADING,
  SET_LOADING_LOGS_FILE
} from '../actions/types';
import { downloadCSVfile } from '../utils/services';

const CancelToken = axios.CancelToken;
let cancelFeedData = null;

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

//Get Actions
export const getActions = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/connector/old?url1=actions');
    if (res.data.actions) {
      res.data.actions.sort((a, b) => {
        const propA = a.name ? a.name.toLowerCase() : '';
        const propB = b.name ? b.name.toLowerCase() : '';
        if (propA > propB) return 1;
        if (propA < propB) return -1;

        return 0;
      });
    }
    dispatch({
      type: GET_ACTIONS,
      payload: res.data.actions ? res.data.actions : []
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_ACTIONS,
      payload: []
    });
  }
};

//Get Actions from the NEW Connector
export const getActionsNew = () => async (dispatch) => {
  try {
    const res = await axios.get(`${serverUrl}?url1=actions&page[size]=1000`);
    const actions = res.data.data
      ? res.data.data.map((action) => {
          const args = action.attributes
            ? action.attributes.args
              ? action.attributes.args
              : {}
            : {};
          const schema = action.attributes
            ? action.attributes['args-schema']
              ? action.attributes['args-schema']
              : {}
            : {};

          return {
            id: action.id,
            groups: action?.attributes?.['group-ids'] || [],
            accounts: action?.attributes?.['account-ids'] || [],
            settings: parseSettings({ args, schema }),
            ...action.attributes
          };
        })
      : [];

    actions.sort((a, b) => {
      const propA = a.name ? a.name.toLowerCase() : '';
      const propB = b.name ? b.name.toLowerCase() : '';
      if (propA > propB) return 1;
      if (propA < propB) return -1;

      return 0;
    });
    dispatch({
      type: GET_ACTIONS_NEW,
      payload: actions
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_ACTIONS_NEW,
      payload: []
    });
  }
};

//Run Action
export const runAction = (id, name, group) => async (dispatch) => {
  try {
    dispatch({
      type: SET_RUN_ACTION_LOADING,
      payload: +id
    });

    const res = await axios.get(
      `/api/connector/old?url1=actions&url2=${id}&url3=run&actionId=${id}&actionName=${encodeURIComponent(
        name
      )}&actionGroup=${encodeURIComponent(group)}`
    );

    if (res.data.success) {
      dispatch({
        type: RUN_ACTION,
        payload: { id: +id, status: 'Delayed' }
      });
    } else {
      dispatch({
        type: RUN_ACTION,
        payload: { id: +id, status: 'Error' }
      });
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: RUN_ACTION,
      payload: { id: +id, status: 'Error' }
    });
  }
};

//Run New Action
export const runActionNew =
  (id, logData, args = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_RUN_ACTION_LOADING,
        payload: id
      });

      const reqBody = JSON.stringify({
        data: {
          type: 'tasks',
          attributes: {
            args
          },
          relationships: {
            action: {
              data: {
                type: 'action',
                id
              }
            }
          }
        },
        logData
      });

      const res = await axios.post(`${serverUrl}?url1=tasks`, reqBody, config);
      let taskId = '';
      if (res.data.data) {
        if (res.data.data.id) {
          taskId = res.data.data.id;
        }
      }

      dispatch({
        type: RUN_ACTION_NEW,
        payload: { id, status: 'DELAYED', taskId }
      });
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch({
        type: RUN_ACTION_NEW,
        payload: { id, status: 'INTERRUPTED' }
      });
    }
    dispatch(getActionsNew());
  };

//Get Action Logs
export const getActionLogs = (params) => async (dispatch) => {
  try {
    dispatch({
      type: SET_ACTION_LOGS_LOADING
    });

    let queries = '';

    for (let key in params) {
      if (params[key] && key !== 'id') {
        queries += `&${key}=${params[key]}`;
      }
    }

    const res = await axios.get(
      `/api/connector/old?url1=actions&url2=${params.id}&url3=logs${queries}`
    );

    dispatch({
      type: GET_ACTION_LOGS,
      payload: res.data.data
        ? res.data.data.logs
          ? res.data.data.logs.sort((a, b) =>
              new Date(a.created_at) > new Date(b.created_at) ? -1 : 1
            )
          : []
        : []
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_ACTION_LOGS,
      payload: []
    });
  }
};

//Get Action Logs New connector
export const getActionLogsNew = (params) => async (dispatch) => {
  try {
    dispatch({
      type: SET_ACTION_LOGS_NEW_LOADING
    });

    let queries = '&include=task';

    for (let key in params) {
      if (params[key] && key !== 'id') {
        queries += `&${key}=${params[key]}`;
      }
    }

    const res = await axios.get(
      `${serverUrl}?url1=actions&url2=${params.id}&url3=logs${queries}`
    );

    let actionLogsNew = res.data.data ? res.data.data : [];
    actionLogsNew = actionLogsNew.map((log) => {
      let res = {
        id: log.id,
        taskId: '',
        level: '',
        message: '',
        'created-at': ''
      };
      if (log.attributes && typeof log.attributes === 'object') {
        res = { ...res, ...log.attributes };
      }

      if (log.relationships && typeof log.relationships === 'object') {
        if (
          log.relationships.task &&
          typeof log.relationships.task === 'object'
        ) {
          if (
            log.relationships.task.data &&
            typeof log.relationships.task.data === 'object'
          ) {
            res.taskId = log.relationships.task.data.id;
          }
        }
      }
      return res;
    });

    const actionLogsPages = res.data.meta
      ? res.data.meta.pagination.last.number
      : 0;
    dispatch({
      type: GET_ACTION_LOGS_NEW,
      payload: {
        actionLogsNew,
        actionLogsPages
      }
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_ACTION_LOGS_NEW,
      payload: {
        actionLogsNew: [],
        actionLogsPages: 0
      }
    });
  }
};

//Update Action args from the NEW Connector
export const updateAction = (data) => async (dispatch) => {
  try {
    //data.id   data.values - have to be parsed to connector style
    const body = {
      data: {
        type: 'actions',
        id: data.id,
        attributes: {}
      },
      logData: data.logData
    };

    const settings = data.values.settings
      ? getConnectorSettings(data.values.settings)
      : null;

    if (settings) {
      if (settings.error) {
        dispatch(setAlert(settings.error, 'error'));
        return;
      }
      body.data.attributes.args = settings;
    }

    await axios.patch(
      `${serverUrl}?url1=actions&url2=${data.id}`,
      JSON.stringify(body),
      config
    );

    dispatch(setAlert('Action settings changed successfully!', 'success'));

    dispatch({
      type: UPDATE_ACTION_SETTINGS,
      payload: data
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
      dispatch(setAlert('Something went wrong. Try again later.', 'error'));
    }
  }
};

//Update Action args from the NEW Connector
export const updateActionSchedule = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_SCHEDULE
    });
    const attributes = {};
    if (data.schedule !== undefined) attributes.schedule = data.schedule;
    if (data.status !== undefined) attributes.schedule_status = data.status;

    const body = {
      data: {
        type: 'actions',
        id: data.id,
        attributes
      },
      logData: data.logData
    };

    await axios.patch(
      `${serverUrl}?url1=actions&url2=${data.id}`,
      JSON.stringify(body),
      config
    );

    dispatch(setAlert('Action settings changed successfully!', 'success'));

    dispatch({
      type: UPDATE_ACTION_SCHEDULE,
      payload: data
    });
    return true;
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      } else {
        dispatch(setAlert('Something went wrong. Try again later.', 'error'));
      }
    } else {
      console.log(err);
      dispatch(setAlert('Something went wrong. Try again later.', 'error'));
    }
    dispatch({
      type: UPDATE_ACTION_SCHEDULE,
      payload: {}
    });
  }
  return false;
};

//Get Action Settings
export const getActionSettings = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SET_ACTION_SETTINGS_LOADING
    });

    const res = await axios.get(
      `/api/connector/old?url1=actions&url2=${id}&url3=settings`
    );

    dispatch({
      type: GET_ACTION_SETTINGS,
      payload: res.data.data
        ? res.data.data.args
          ? res.data.data.args[0]
            ? res.data.data.args[0].settings
            : {}
          : {}
        : {}
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_ACTION_SETTINGS,
      payload: {}
    });
  }
};

//Get Feeds List
export const getFeedsList = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/connector/old?url1=items_feeds`);

    dispatch({
      type: GET_ALL_FEEDS,
      payload: res.data.data
        ? res.data.data.items_feeds
          ? res.data.data.items_feeds.sort((a, b) => {
              const propA = a.name ? a.name.toLowerCase() : '';
              const propB = b.name ? b.name.toLowerCase() : '';
              if (propA > propB) return 1;
              if (propA < propB) return -1;

              return 0;
            })
          : []
        : []
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_ALL_FEEDS,
      payload: []
    });
  }
};

//Get Feeds List
export const getFeedItems = (params) => async (dispatch) => {
  const result = {
    items: [],
    totalItems: null,
    brands: []
  };
  try {
    dispatch({
      type: SET_LOADING_FEED_ITEMS
    });

    if (cancelFeedData) {
      cancelFeedData();
      cancelFeedData = null;
    }

    const config2 = {
      cancelToken: new CancelToken(function executor(c) {
        cancelFeedData = c;
      })
    };

    const exceptions = ['id', 'brand_id'];
    let query = '';
    for (const key in params) {
      query +=
        !exceptions.includes(key) && params[key]
          ? `&${key}=${params[key]}`
          : '';
    }

    if (params['brand_id'].length)
      query += `&brand_id=${params['brand_id'].map((b) => b.id).join()}`;

    const res = await axios.get(
      `/api/connector/old?url1=items_feeds&url2=items&items_feed_id=${params.id}${query}`,
      config2
    );

    if (res.data.data) {
      const { feed_items, brands, navigation } = res.data.data;
      if (feed_items) result.items = feed_items;
      if (brands) {
        for (const key in brands) {
          if (brands[key]) {
            result.brands.push({
              id: key,
              name: brands[key]
            });
          }
        }
      }
      if (navigation) result.totalItems = navigation.count;
    }

    dispatch({
      type: GET_FEED_ITEMS,
      payload: result
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_FEED_ITEMS,
      payload: result
    });
  }
};

export const setSelectedItems = (items) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_ITEMS,
    payload: items
  });
};

//Send Feed Items Action
export const sendFeedAction =
  ({ body, type, filters, id, logData = {} }) =>
  async (dispatch) => {
    let identifyBody = { id: [] };

    if (type === 'identify') {
      dispatch({
        type: SET_LOADING_IDENTIFY_ITEMS,
        payload: true
      });
      //Get Items in statuses 0,1,2 Not Defined; Not Connected; Doubles
      const res = await axios.get(
        `/api/connector/old?url1=items_feeds&url2=items_all_fields?items_feed_id=${id}&status=0,1,2`
      );

      if (res.data.data) {
        identifyBody.id = res.data.data.data.map((arr) => arr[0]);
      }
    } else {
      dispatch({
        type: SET_LOADING_SEND_ITEMS,
        payload: true
      });
    }

    try {
      const reqBody = type === 'identify' ? identifyBody : body;
      const res = await axios.post(
        `/api/connector/old?url1=items_feeds&url2=items&url3=${type}`,
        JSON.stringify({ ...reqBody, logData }),
        config
      );
      if (res.data.success) {
        dispatch(setAlert(`Items send to ${type}`, 'success'));
      } else {
        dispatch(setAlert(res.data.error, 'error'));
      }

      if (type === 'identify') {
        dispatch({
          type: SET_LOADING_IDENTIFY_ITEMS,
          payload: false
        });
      } else {
        dispatch({
          type: SET_LOADING_SEND_ITEMS,
          payload: false
        });
      }
      //dispatch(getFeedItems({ id, ...filters }));
      dispatch(setSelectedItems([]));
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      if (type === 'identify') {
        dispatch({
          type: SET_LOADING_IDENTIFY_ITEMS,
          payload: false
        });
      } else {
        dispatch({
          type: SET_LOADING_SEND_ITEMS,
          payload: false
        });
      }
    }
  };

//Get Actions Updates from the NEW Connector
export const getActionUpdates = () => async (dispatch) => {
  try {
    const res = await axios.get(`${serverUrl}?url1=actions&url2=fast`);
    const updates = res.data.data
      ? res.data.data.map((data) => {
          let res = { id: data.id };
          if (data.attributes) {
            res = { ...res, ...data.attributes };
          }
          return res;
        })
      : [];

    dispatch({
      type: GET_ACTIONS_UPDATES,
      payload: updates
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_ACTIONS_UPDATES,
      payload: []
    });
  }
};

//Update Action args from the NEW Connector
export const stopAction =
  ({ taskId, actionId, logData = {} }) =>
  async (dispatch) => {
    dispatch({
      type: SET_STOP_ACTION_LOADING,
      payload: actionId
    });
    try {
      await axios.patch(
        `${serverUrl}?url1=tasks&url2=${taskId}&url3=interrupt`,
        JSON.stringify({ logData }),
        config
      );

      dispatch({
        type: STOP_ACTION,
        payload: { taskId, actionId, status: 'INTERRUPTED' }
      });
    } catch (err) {
      if (err.response) {
        const errors = err.response.data.errors;
        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
        }
      } else {
        console.log(err);
        dispatch(setAlert('Something went wrong. Try again later.', 'error'));
      }
    }
    dispatch(getActionsNew());
  };

//Get Action Logs File New connector
export const getActionLogsFile = (headers, params) => async (dispatch) => {
  dispatch({
    type: SET_LOADING_LOGS_FILE,
    payload: true
  });

  let pages = 1;
  const logsData = [];
  const getLogs = async (params) => {
    let queries = '&include=task';

    for (let key in params) {
      if (params[key] && key !== 'id') {
        queries += `&${key}=${key === 'page[size]' ? 1000 : params[key]}`;
      }
    }
    try {
      const res = await axios.get(
        `${serverUrl}?url1=actions&url2=${params.id}&url3=logs${queries}`
      );

      let actionLogsNew = res.data.data ? res.data.data : [];
      actionLogsNew = actionLogsNew.map((log) => {
        let res = {
          id: log.id,
          taskId: '',
          level: '',
          message: '',
          'created-at': ''
        };
        if (log.attributes && typeof log.attributes === 'object') {
          res = { ...res, ...log.attributes };
        }

        if (log.relationships && typeof log.relationships === 'object') {
          if (
            log.relationships.task &&
            typeof log.relationships.task === 'object'
          ) {
            if (
              log.relationships.task.data &&
              typeof log.relationships.task.data === 'object'
            ) {
              res.taskId = log.relationships.task.data.id;
            }
          }
        }
        return res;
      });

      //Add data to array
      actionLogsNew.forEach((log) => {
        logsData.push(
          headers.map((h) =>
            log[h.key] ? String(log[h.key]).replace(/,/g, ' ') : ''
          )
        );
      });

      pages =
        res.data.meta &&
        res.data.meta.pagination &&
        res.data.meta.pagination.last
          ? +res.data.meta.pagination.last.number
          : 1;

      if (pages > +params['page[number]']) {
        getLogs({ ...params, 'page[number]': +params['page[number]'] + 1 });
      } else {
        downloadCSVfile({
          headers: headers.map((h) => h.label),
          data: logsData,
          fileName: `logs_file_${new Date().getTime()}.csv`
        });

        dispatch({
          type: SET_LOADING_LOGS_FILE,
          payload: false
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: SET_LOADING_LOGS_FILE,
        payload: false
      });
    }
  };

  getLogs(params);
};

export const setActionGroup = (id) => (dispatch) => {
  dispatch({
    type: SELECT_ACTION_GROUP,
    payload: id
  });
};

export const openConnectorModal = (data) => (dispatch) => {
  dispatch({
    type: OPEN_CONNECTOR_MODAL,
    payload: data
  });
};

export const closeConnectorModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_CONNECTOR_MODAL
  });
};

export const clearConnector = () => (dispatch) => {
  dispatch({
    type: CLEAR_CONNECTOR
  });
};
