class ScheduleMethods {
  constructor() {
    this.scheduleTypes = [
      { label: 'Every * Minutes', value: 'everymin' },
      { label: 'Every * Hours', value: 'everyhour' },
      { label: 'Daily', value: 'daily' },
      { label: 'Weekly', value: 'weekly' },
      { label: 'Monthly', value: 'monthly' },
    ];

    this.everyMinutes = [
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '30', value: 30 },
      { label: '40', value: 40 },
    ];

    this.everyHours = [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '6', value: 6 },
      { label: '8', value: 8 },
      { label: '12', value: 12 },
    ];

    this.weekDays = [
      { label: 'Sunday', value: 0 },
      { label: 'Monday', value: 1 },
      { label: 'Tuesday', value: 2 },
      { label: 'Wednesday', value: 3 },
      { label: 'Thursday', value: 4 },
      { label: 'Friday', value: 5 },
      { label: 'Saturday', value: 6 },
    ];

    this.monthDays = [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '5', value: 5 },
      { label: '7', value: 7 },
      { label: '10', value: 10 },
      { label: '15', value: 15 },
      { label: '20', value: 20 },
      { label: '30', value: 30 },
    ];
  }

  everyDayHours = () => {
    const arr = [];
    for (let i = 0; i <= 23; i += 1) {
      arr.push({ label: `${i}`, value: i });
    }
    return arr;
  };

  everyHourMinutes = (step) => {
    const arr = [];
    for (let i = 0; i < 60; i += step) {
      arr.push({ label: `${i}`, value: i });
    }
    return arr;
  };

  everyDayMonth = () => {
    const arr = [];
    for (let i = 1; i <= 28; i += 1) {
      arr.push({ label: `${i}`, value: i });
    }
    return arr;
  };

  calculateHoursDiff = (hour, timezone) => {
    const getDiff = (diff) => {
      if (diff < 0) {
        return diff + 24;
      }
      if (diff > 23) {
        return diff - 24;
      }
      return diff;
    };

    if (typeof hour === 'object') {
      return hour
        .map((h) => {
          const diff = Number(h) + Number(timezone);
          return getDiff(diff);
        })
        .join();
    }
    const diff = Number(hour) + Number(timezone);
    return getDiff(diff);
  };

  calculateDaysDiff = ({ hour, day, timezone, dayMin, dayMax }) => {
    const diff = Number(hour) + Number(timezone);
    let dayDiff = 0;
    const data = {
      hour: diff,
      day,
    };

    if (diff < 0) {
      data.hour = diff + 24;
      dayDiff = -1;
    }

    if (diff > 23) {
      data.hour = diff - 24;
      dayDiff = 1;
    }

    data.day = dayDiff + data.day;

    if (data.day < dayMin) {
      data.day = dayMax;
    }

    if (data.day > dayMax) {
      data.day = dayMin;
    }

    return data;
  };

  getTimeParts = (date) => {
    return {
      hours: date.getHours(),
      minutes: date.getMinutes(),
    };
  };

  calculateDailyCron = ({ time, day, timezone }) => {
    const currentTime = this.getTimeParts(time);
    return `${currentTime.minutes} ${this.calculateHoursDiff(
      currentTime.hours,
      -1 * timezone
    )} */${day} * *`;
  };

  calculateMonthlyCron = ({ time, day, timezone }) => {
    const currentTime = this.getTimeParts(time);
    const data = day.map(
      (d) =>
        this.calculateDaysDiff({
          hour: currentTime.hours,
          day: d,
          timezone: -1 * timezone,
          dayMin: 1,
          dayMax: 31,
        }).day
    );
    return `${currentTime.minutes} ${this.calculateHoursDiff(
      currentTime.hours,
      -1 * timezone
    )} ${data.join(',')} * *`;
  };

  calculateWeeklyCron = ({ time, day, timezone }) => {
    const currentTime = this.getTimeParts(time);
    const data = day.map((d) =>
      this.calculateDaysDiff({
        hour: currentTime.hours,
        day: d,
        timezone: -1 * timezone,
        dayMin: 0,
        dayMax: 6,
      })
    );
    return `${currentTime.minutes} ${data[0].hour} * * ${data
      .map((d) => d.day)
      .join(',')}`;
  };

  calculateHourCron = ({ time, isHour, timezone, min }) =>
    `${min !== '' ? min : '0'} ${isHour ? '*/' : ''}${
      isHour ? time : this.calculateHoursDiff(time, -1 * timezone)
    } * * *`;

  calculateMinCron = ({ time, isMin }) => `${isMin ? '*/' : ''}${time} * * * *`;

  getCronType = (cron) => {
    const data = [];
    const cronPoints = cron.split(' ');
    cronPoints.forEach((value, i) => {
      if (value !== '*') data.push(i);
    });
    let type = '';
    if (data.includes(0) && data.length === 1) {
      type = 'everymin';
    } else if (data.includes(0) && data.includes(1) && data.length === 2) {
      type = 'everyhour';
    } else if (
      data.includes(0) &&
      data.includes(1) &&
      data.includes(2) &&
      cronPoints[2].includes('*') &&
      data.length === 3
    ) {
      type = 'daily';
    } else if (
      data.includes(0) &&
      data.includes(1) &&
      data.includes(4) &&
      data.length === 3
    ) {
      type = 'weekly';
    } else if (
      data.includes(0) &&
      data.includes(1) &&
      data.includes(2) &&
      data.length === 3
    ) {
      type = 'monthly';
    }

    return type;
  };

  getCronTime = (cron, timezone = 0) => {
    const minutes =
      cron[0] !== undefined ? (!cron[0].includes('*') ? +cron[0] : null) : null;
    const hours =
      cron[1] !== undefined ? (!cron[1].includes('*') ? +cron[1] : null) : null;
    const date = new Date();
    if (minutes !== null) date.setMinutes(minutes);
    if (hours !== null && !isNaN(hours))
      date.setHours(this.calculateHoursDiff(hours, timezone));
    return date;
  };

  getCronDay = (cron) => {
    return cron[2] !== undefined
      ? !cron[2].includes('*')
        ? +cron[2]
        : cron[2] !== '*'
        ? +cron[2].replace('*/', '')
        : ''
      : '';
  };

  getCronMonthly = (cron, timezone) => {
    const hours =
      cron[1] !== undefined ? (!cron[1].includes('*') ? +cron[1] : '') : '';
    const day =
      cron[2] !== undefined ? (!cron[2].includes('*') ? cron[2] : '') : '';
    if (hours !== '' && day !== '') {
      const days = day.split(',');
      return days.map(
        (d) =>
          this.calculateDaysDiff({
            hour: hours,
            day: +d,
            timezone,
            dayMin: 1,
            dayMax: 31,
          }).day
      );
    }
    return [];
  };

  getCronHourMin = (cron, i = 0) => {
    return cron[i] !== undefined
      ? cron[i].includes('*/')
        ? +cron[i].replace('*/', '')
        : ''
      : '';
  };

  getCronMins = (cron) => {
    return cron[0] !== undefined
      ? !cron[0].includes('*')
        ? cron[0].split(',').map((c) => +c)
        : []
      : [];
  };

  getCronHours = (cron, timezone) => {
    return cron[1] !== undefined
      ? !cron[1].includes('*')
        ? cron[1].split(',').map((c) => this.calculateHoursDiff(+c, timezone))
        : []
      : [];
  };

  getCronMinute = (cron) => {
    return cron[0] !== undefined
      ? !cron[0].includes('*')
        ? +cron[0]
        : ''
      : '';
  };

  getWeekDayTime = (cron, timezone) => {
    const hours =
      cron[1] !== undefined ? (!cron[1].includes('*') ? +cron[1] : '') : '';
    const day =
      cron[4] !== undefined ? (!cron[4].includes('*') ? cron[4] : '') : '';

    if (day !== '' && hours !== '') {
      const days = day.split(',');
      return days.map(
        (d) =>
          this.calculateDaysDiff({
            hour: hours,
            day: +d,
            timezone,
            dayMin: 0,
            dayMax: 6,
          }).day
      );
    }

    return [];
  };

  getMinHour = ({ day, timezone, isMin }) => {
    if (day.includes(1) && timezone > 0 && isMin) {
      return timezone;
    }

    if (day.includes(28) && timezone < 0 && !isMin) {
      return 23 + timezone;
    }

    return isMin ? 0 : 23;
  };
}

export default new ScheduleMethods();
