import { useState, useEffect, useMemo } from 'react';
import ScheduleMethods from './ScheduleMethods';
import { InfoTooltip } from '../../../utils/components';
import { MenuItem, Select } from '@material-ui/core';

const EveryMin = ({ timezone, setCron, defaultCron }) => {
  const [formData, setFormData] = useState({
    everyMin: ScheduleMethods.getCronHourMin(defaultCron),
    everyHourMin: ScheduleMethods.getCronMins(defaultCron),
  });

  const { everyMin, everyHourMin } = formData;

  const onChangeMin = (e) => {
    setFormData({ everyMin: e.target.value, everyHourMin: [] });
  };

  const onChangeHourMin = (e) => {
    setFormData({ everyMin: '', everyHourMin: e.target.value });
  };

  useEffect(() => {
    const isMin = everyMin !== '';
    if (isMin || everyHourMin.length) {
      setCron(
        ScheduleMethods.calculateMinCron({
          time: isMin ? everyMin : everyHourMin,
          isMin,
        })
      );
    }
  }, [everyMin, everyHourMin, setCron]);

  const MenuProps = useMemo(
    () => ({
      classes: { paper: 'select-list' },
      variant: 'menu',
      getContentAnchorEl: null,
    }),
    []
  );

  return (
    <ul>
      <li className='schedule-main-row schedule-subrow'>
        <p className='schedule-row-name bold'>Repeat every</p>
        <Select
          classes={{
            root: 'settings-edit-select',
          }}
          MenuProps={MenuProps}
          name='everyMin'
          value={everyMin}
          onChange={onChangeMin}
          className='schedule-select-time'
          variant='outlined'
        >
          {ScheduleMethods.everyMinutes.map((e, i) => (
            <MenuItem key={`everymin-${i}`} value={e.value}>
              {e.label}
            </MenuItem>
          ))}
        </Select>
        <b className='schedule-add-info'>minutes</b>
        <InfoTooltip text='10:10, 10:20, 10:30, ...' />
      </li>
      <li className='schedule-main-row schedule-subrow bold'>or</li>
      <li className='schedule-main-row schedule-subrow'>
        <p className='schedule-row-name bold'>Repeat each selected minute</p>
        <Select
          multiple
          classes={{
            root: 'settings-edit-select',
          }}
          MenuProps={MenuProps}
          name='everyHourMin'
          value={everyHourMin}
          onChange={onChangeHourMin}
          className='schedule-time-box'
          variant='outlined'
        >
          {ScheduleMethods.everyHourMinutes(5).map((e, i) => (
            <MenuItem key={`everyhourmin-${i}`} value={e.value}>
              {e.label}
            </MenuItem>
          ))}
        </Select>
        <InfoTooltip text='10:05, 11:05, 12:05, ...' />
      </li>
    </ul>
  );
};

export default EveryMin;
