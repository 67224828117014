import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { connect } from 'react-redux';
import { MdSearch } from 'react-icons/md';
import classes from './ActionSearch.module.css';
import { ListSceleton } from '../../../utils/components';
import { Link } from 'react-router-dom';
import useDebouce from '../../../hooks/useDebouce';

const ActionSearch = ({
  connector: { actions, actionsNew, loadingNew, loading },
  groups
}) => {
  const [text, setText] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [isHidden, setHidden] = useState(true);
  const [searchResults, setSearchResult] = useState([]);
  const selectArea = useRef(null);
  const selectList = useRef(null);
  const searchDebounce = useDebouce((value) => {
    const filterAction = (action) => {
      let groupName = action.group_name ? action.group_name : '';
      if (!groupName && action.groups) {
        const group = groups.find((g) => +g.id === +action.groups[0]);
        if (group) groupName = group.name;
      }
      if (!action.name) return false;
      if (value) {
        const isGroup = groupName
          ? groupName.match(new RegExp(`${value}`, 'gi'))
          : false;
        return action.name.match(new RegExp(`${value}`, 'gi')) || isGroup;
      }
      return true;
    };
    setSearchResult([
      ...actions.filter(filterAction).map((action) => ({
        name: `${action.name} (${action.group_name})`,
        type: 'old',
        url: `/actionsold/${encodeURIComponent(action.group_name)}/action/${
          action.id
        }/logs/`
      })),
      ...actionsNew.filter(filterAction).map((action) => {
        let groupName = '';
        if (action.groups) {
          const group = groups.find((g) => +g.id === +action.groups[0]);
          if (group) groupName = group.name;
        }
        return {
          name: `${action.name} (${groupName})`,
          type: 'new',
          url: `/actionsnew/${action.groups ? action.groups[0] : '0'}/action/${
            action.id
          }/logs/`
        };
      })
    ]);
  }, 100);

  const onChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    if (newText?.length >= 3) {
      searchDebounce(newText?.trim());
      if (!isOpen) {
        setOpen(true);
      }
    } else {
      if (isOpen) {
        setOpen(false);
      }
    }
  };

  const selectItem = () => {
    setOpen(false);
    setText('');
  };

  const openSearch = () => {
    if (text.length >= 3 && !isOpen) setOpen(true);
  };

  const checkSelect = useCallback(
    (e) => {
      if (!isOpen || !selectArea.current || !selectList.current) return;
      if (
        !selectArea.current.contains(e.target) &&
        !selectList.current.contains(e.target)
      ) {
        setOpen(false);
      }
    },
    [isOpen, setOpen, selectArea, selectList]
  );

  //Control closing Search results
  useEffect(() => {
    window.addEventListener('click', checkSelect);
    return () => {
      window.removeEventListener('click', checkSelect);
    };
  }, [checkSelect]);
  return (
    <div className={classes.searchContainer}>
      <div ref={selectArea} className={classes.search}>
        <input
          className={`${classes.searchInput} ${
            isHidden ? '' : classes.searchInputFull
          }`}
          type="text"
          placeholder="Action or Group Name"
          value={text}
          onChange={onChange}
          onClick={openSearch}
        />
        <MdSearch
          className={classes.searchIcon}
          onClick={() => setHidden(!isHidden)}
        />
      </div>
      <div
        ref={selectList}
        style={{ display: isOpen ? '' : 'none' }}
        className={classes.searchResults}
      >
        {loading || loadingNew ? (
          <ListSceleton />
        ) : (
          <Fragment>
            {searchResults.length ? (
              searchResults.map((item, i) => (
                <Link
                  key={`search-item-${i}`}
                  className={classes.searchResultsRow}
                  onClick={selectItem}
                  to={item.url}
                >
                  <span
                    className={
                      item.type === 'new'
                        ? 'connector-icon-new'
                        : 'connector-icon-old'
                    }
                  >
                    {item.type}
                  </span>
                  {item.name}
                </Link>
              ))
            ) : (
              <p className={classes.searchNoresults}>No results found...</p>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  connector: state.connector,
  groups: state.connections.groupsNew
});

export default connect(mapStateToProps)(ActionSearch);
