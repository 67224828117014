import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import {
  GET_CONNECTIONS,
  CLEAR_CONNECTIONS,
  SET_CONNECTIONS_LOADING,
} from './types';

//Get all Connections and Feeds
export const getConnections = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({ type: SET_CONNECTIONS_LOADING });

  try {
    const res = await axios.get('/api/connection');

    dispatch({
      type: GET_CONNECTIONS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
    dispatch({
      type: GET_CONNECTIONS,
      payload: {},
    });
  }
};

//Clear Connections
export const clearConnections = () => (dispatch) => {
  dispatch({
    type: CLEAR_CONNECTIONS,
  });
};
