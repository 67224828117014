import axios from 'axios';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import { parseSettings, getConnectorSettings } from '../utils/parsers';
import { serverUrl } from '../utils/constants';
import {
  ADD_PROFILE_USER,
  EDIT_PROFILE_USER,
  DELETE_PROFILE_USER,
  GET_PROFILE_USERS,
  CLEAR_PROFILE,
  GET_PROFILE_ACCOUNTS,
  SET_EMAIL_LOADING,
  UPDATE_ACCOUNT_SETTINGS,
  GET_PROFILE_LOGS,
  SET_PROFILE_LOGS_LOADING,
  GET_ORDERS_ERRORS,
  SET_ORDERS_ERRORS_LOADING,
} from './types';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// Add Profile User
export const addProfileUser = (data) => async (dispatch) => {
  try {
    const body = JSON.stringify(data);
    const res = await axios.post('/api/users/profile', body, config);

    dispatch({
      type: ADD_PROFILE_USER,
      payload: res.data,
    });
    dispatch(setAlert('Profile User added successfully!', 'success'));
    return res.data;
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: ADD_PROFILE_USER,
      payload: null,
    });
    return null;
  }
};

// Change Profile User
export const updateProfileUser = (data) => async (dispatch) => {
  try {
    const body = JSON.stringify(data);
    const res = await axios.put('/api/users/profile', body, config);

    dispatch({
      type: EDIT_PROFILE_USER,
      payload: res.data,
    });
    dispatch(setAlert('Profile User changed successfully!', 'success'));
    return res.data;
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: EDIT_PROFILE_USER,
      payload: null,
    });
    return null;
  }
};

//Get Profile Users
export const getProfileUsers = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      id ? `/api/users/profile?id=${id}` : '/api/users/profile'
    );

    dispatch({
      type: GET_PROFILE_USERS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: GET_PROFILE_USERS,
      payload: [],
    });
  }
  return true;
};

//Delete Connection
export const deleteProfileUser = (id) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    await axios.delete(`/api/users/${id}`);

    dispatch({
      type: DELETE_PROFILE_USER,
      payload: id,
    });
    dispatch(setAlert('User deleted successfully!', 'success'));
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
  }
};

export const sendEmail = (data) => async (dispatch) => {
  dispatch({
    type: SET_EMAIL_LOADING,
    payload: true,
  });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const encodedFiles = [];

  try {
    if (data.files) {
      for (let i = 0; i < data.files.length; i++) {
        const result = await toBase64(data.files[i]).catch((e) => Error(e));
        if (result instanceof Error) {
          dispatch(setAlert(result.message, 'error'));
          continue;
        }
        encodedFiles.push({
          name: data.files[i].name,
          content: result.replace(`data:${data.files[i].type};base64,`, ''),
          type: data.files[i].type,
        });
      }
    }
    if (encodedFiles.length) data.files = encodedFiles;
    const body = JSON.stringify(data);
    const res = await axios.post('/api/users/email', body, config);
    dispatch(setAlert(data.message ? data.message : res.data, 'success', 5000));
    dispatch({
      type: SET_EMAIL_LOADING,
      payload: false,
    });
    return res.data;
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
  }

  dispatch({
    type: SET_EMAIL_LOADING,
    payload: false,
  });
  return null;
};

//Get Account settings from the NEW Connector
export const getAccounts = () => async (dispatch) => {
  try {
    let accounts = [];
    const res = await axios.get(`${serverUrl}?url1=accounts&page[size]=1000`);
    if (res.data.data) {
      accounts = res.data.data.map((d) => {
        const args = d.attributes
          ? d.attributes.settings
            ? d.attributes.settings
            : {}
          : {};
        const schema = d.attributes
          ? d.attributes['settings-schema']
            ? d.attributes['settings-schema'].properties
              ? d.attributes['settings-schema'].properties
              : {}
            : {}
          : {};

        return {
          id: d.id,
          name: d.attributes.name,
          settings: parseSettings({ args, schema }),
        };
      });
    }

    dispatch({
      type: GET_PROFILE_ACCOUNTS,
      payload: accounts,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_PROFILE_ACCOUNTS,
      payload: [],
    });
  }
};

//Get Account settings from the NEW Connector
export const updateAccount = (data) => async (dispatch) => {
  try {
    //data.id   data.values - have to be parsed to connector style
    const body = {
      data: {
        type: 'accounts',
        id: data.id,
        attributes: {},
      },
    };

    const settings = data.values.settings
      ? getConnectorSettings(data.values.settings)
      : null;
    if (settings) {
      if (settings.error) {
        dispatch(setAlert(settings.error, 'error'));
        return;
      }
      body.data.attributes.settings = settings;
    }

    await axios.patch(
      `${serverUrl}?url1=accounts&url2=${data.id}`,
      JSON.stringify(body),
      config
    );

    dispatch(setAlert('Account settings changed successfully!', 'success'));

    dispatch({
      type: UPDATE_ACCOUNT_SETTINGS,
      payload: data,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
      dispatch(setAlert('Something went wrong. Try again later.', 'error'));
    }
  }
};

//Get Profile Users
export const getProfileLogs = (params) => async (dispatch) => {
  dispatch({ type: SET_PROFILE_LOGS_LOADING });
  try {
    let query = '';
    for (const key in params) {
      if (!params[key] || params[key] === 'All') continue;
      query += `${query ? '&' : '?'}${key}=${encodeURIComponent(params[key])}`;
    }
    const res = await axios.get(`/api/logs${query}`);

    dispatch({
      type: GET_PROFILE_LOGS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
    dispatch({
      type: GET_PROFILE_LOGS,
      payload: [],
    });
  }
};

export const getOrdersErrors = () => async (dispatch) => {
  dispatch({ type: SET_ORDERS_ERRORS_LOADING });
  try {
    const res = await axios.get(
      `${serverUrl}?url1=groups&url2=24&url3=last-logs&filter[level]=ERROR&page[size]=1000&include=action`
    );
    const actions = [];
    res?.data?.data?.forEach((log) => {
      if (log?.attributes) {
        actions.push({
          date: log.attributes['created-at'],
          message: log.attributes.message,
          actionId: log.relationships?.action?.data?.id,
        });
      }
    });

    dispatch({
      type: GET_ORDERS_ERRORS,
      payload: actions,
    });
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }

    dispatch({
      type: GET_ORDERS_ERRORS,
      payload: [],
    });
  }
};

export const clearProfile = () => (dispatch) => {
  dispatch({
    type: CLEAR_PROFILE,
  });
};
