import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout, switchRoleAccount } from '../../actions/auth';
import ConnectorModal from '../../components/layout/ConnectorModal';
import {
  distributorList,
  marketplaces,
  mainCategories,
  roles
} from '../../utils/constants';

import logo from '../../img/logo.png';

// Icons
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdHelpOutline,
  MdDvr
} from 'react-icons/md';
import { GoGear, GoBell } from 'react-icons/go';
import { FaUsers } from 'react-icons/fa';
import { SiGithubactions } from 'react-icons/si';
import { CgFeed } from 'react-icons/cg';
import { IoHome } from 'react-icons/io5';
import { GrMenu, GrFormClose } from 'react-icons/gr';
import { useMemo } from 'react';
import { BsUiChecksGrid } from 'react-icons/bs';
import { UserAccessList } from './components/UserAccessList';

const Navbar = ({
  connections: { actionGroups, feedsList, actionGroupsNew },
  auth: { profile, user, sharedAccess },
  logout,
  switchRoleAccount
}) => {
  const isAdminSuperAdmin = useMemo(
    () => [roles.admin.name, roles.superadmin.name].includes(user.role),
    [user.role]
  );
  const { pathname } = useLocation();

  const initialMainCategories = {};
  mainCategories.forEach(
    (m) => (initialMainCategories[m] = pathname.includes(m))
  );

  const [isOpen, setOpen] = useState(false);
  const [isOpenMobile, setOpenModile] = useState(false);
  const [categories, setCategories] = useState(initialMainCategories);

  const onMouseOver = (e) => {
    if (e.currentTarget.contains(e.relatedTarget)) return;
    if (window.innerWidth >= 768) {
      setOpen(true);
    }
  };

  const onMouseOut = (e) => {
    if (e.currentTarget.contains(e.relatedTarget)) return;
    if (window.innerWidth >= 768) {
      setOpen(false);
    }
  };

  const showLeftMenu = (e) => {
    setOpenModile(!isOpenMobile);
    setOpen(true);
  };

  useEffect(() => {
    //Select main category in the Navbar according to the url
    const newState = {};
    mainCategories.forEach((c) => {
      newState[c] = pathname ? pathname.split('/')[1] === c : false;
    });
    setCategories(newState);

    //Set subcategory if necessary
    document
      .querySelectorAll('a[data-type="subcategory"]')
      .forEach((subcategory) => {
        const href = subcategory.getAttribute('href');
        const li = subcategory.querySelector('li');
        if (li) {
          const isActive = li.classList.contains('nav-left-main-active');
          if (pathname.includes(href)) {
            if (!isActive) li.classList.add('nav-left-main-active');
          } else {
            if (isActive) li.classList.remove('nav-left-main-active');
          }
        }
      });
  }, [pathname]);

  return (
    <div className="nav-box">
      <div
        // style={{ display: isOpenMobile ? '' : 'none' }}
        className={`${isOpen ? 'nav-left-full' : 'nav-left'} ${
          isOpenMobile ? 'active-menu' : ''
        }`}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <div className="nav-left-close">
          <GrFormClose className="nav-left-close-icon" onClick={showLeftMenu} />
        </div>
        <div className="nav-left-header">
          <Link to="/dashboard/" className="nav-left-option-header">
            <IoHome className="nav-left-icon" />
            <h1>WebyConnector</h1>
          </Link>
        </div>
        <div className={isOpen ? 'nav-left-body-full' : 'nav-left-body'}>
          {profile.connectorNew && (
            <div className="nav-left-option-list">
              <Link
                to="/actionsnew/"
                className={`nav-left-option ${
                  !categories.actionsnew ? '' : 'selected-category'
                }`}
              >
                <SiGithubactions className="nav-left-icon" />
                <span>Action Center {profile.connector && '(new)'}</span>
                {categories.actionsnew ? (
                  <MdKeyboardArrowUp
                    className="nav-left-arrow"
                    name="arrow-up"
                  />
                ) : (
                  <MdKeyboardArrowDown
                    className="nav-left-arrow"
                    name="arrow-down"
                  />
                )}
              </Link>
              <ul
                className={`nav-left-list ${
                  categories.actionsnew ? '' : 'nav-left-list-disabled'
                }`}
              >
                {actionGroupsNew
                  .filter((g) => !g['is-hidden'])
                  .map((g, i) => (
                    <Link
                      key={`group-${i}`}
                      className="white-link"
                      data-type="subcategory"
                      to={`/actionsnew/${g.id}/`}
                    >
                      <li className="nav-left-option">
                        {marketplaces.find((d) => d.value === g.marketplace) ? (
                          <img
                            src={
                              marketplaces.find(
                                (d) => d.value === g.marketplace
                              ).icon
                            }
                            alt={g.name}
                            className="nav-left-img"
                          />
                        ) : marketplaces.find((d) => d.value === 'Other') ? (
                          <img
                            src={
                              marketplaces.find((d) => d.value === 'Other').icon
                            }
                            alt={g.name}
                            className="nav-left-img"
                          />
                        ) : null}
                        {g.name}
                      </li>
                    </Link>
                  ))}
              </ul>
            </div>
          )}
          {profile.connector && (
            <div className="nav-left-option-list">
              <Link
                to="/actionsold/"
                className={`nav-left-option ${
                  !categories.actionsold ? '' : 'selected-category'
                }`}
              >
                <SiGithubactions className="nav-left-icon" />
                <span>Action Center (old)</span>
                {categories.actionsold ? (
                  <MdKeyboardArrowUp
                    className="nav-left-arrow"
                    name="arrow-up"
                  />
                ) : (
                  <MdKeyboardArrowDown
                    className="nav-left-arrow"
                    name="arrow-down"
                  />
                )}
              </Link>
              <ul
                className={`nav-left-list ${
                  categories.actionsold ? '' : 'nav-left-list-disabled'
                }`}
              >
                {actionGroups.map((c, i) => (
                  <Link
                    key={`connection-${i}`}
                    className="white-link"
                    data-type="subcategory"
                    to={`/actionsold/${encodeURIComponent(c.name)}/`}
                  >
                    <li className="nav-left-option">
                      {marketplaces.find((d) => d.value === c.marketplace) ? (
                        <img
                          src={
                            marketplaces.find((d) => d.value === c.marketplace)
                              .icon
                          }
                          alt={c.name}
                          className="nav-left-img"
                        />
                      ) : null}
                      {c.name}
                    </li>
                  </Link>
                ))}
              </ul>
            </div>
          )}
          {profile.connector && (
            <div className="nav-left-option-list">
              <Link
                to="/feeds/"
                className={`nav-left-option ${
                  !categories.feeds ? '' : 'selected-category'
                }`}
              >
                <CgFeed className="nav-left-icon" />
                <span>Feed Center</span>
                {categories.feeds ? (
                  <MdKeyboardArrowUp
                    className="nav-left-arrow"
                    name="arrow-up"
                  />
                ) : (
                  <MdKeyboardArrowDown
                    className="nav-left-arrow"
                    name="arrow-down"
                  />
                )}
              </Link>
              <ul
                className={`nav-left-list ${
                  categories.feeds ? '' : 'nav-left-list-disabled'
                }`}
              >
                {feedsList.map((c, i) => (
                  <Link
                    key={`feed-old-${i}`}
                    className="white-link"
                    data-type="subcategory"
                    to={`/feeds/${c.feedId}/`}
                  >
                    <li className="nav-left-option">
                      {distributorList.find((d) => d.name === c.name) ? (
                        <img
                          src={
                            distributorList.find((d) => d.name === c.name).icon
                          }
                          alt={c.name}
                          className="nav-left-img"
                        />
                      ) : null}
                      {c.name}
                    </li>
                  </Link>
                ))}
              </ul>
            </div>
          )}
          <div className="nav-left-separator"></div>
          <Link
            to="/settings/"
            className={`nav-left-option ${
              !categories.settings ? '' : 'selected-category'
            }`}
          >
            <GoGear className="nav-left-icon" />
            Settings
          </Link>
          {isAdminSuperAdmin && (
            <Link
              to="/users/"
              className={`nav-left-option ${
                !categories.users ? '' : 'selected-category'
              }`}
            >
              <FaUsers className="nav-left-icon" />
              Users
            </Link>
          )}
          {isAdminSuperAdmin && (
            <Link
              to="/shared/"
              className={`nav-left-option ${
                !categories.shared ? '' : 'selected-category'
              }`}
            >
              <BsUiChecksGrid className="nav-left-icon" />
              Shared Access
            </Link>
          )}
          <Link
            to="/logs/"
            className={`nav-left-option ${
              !categories.logs ? '' : 'selected-category'
            }`}
          >
            <MdDvr className="nav-left-icon" />
            Logs
          </Link>
          <Link
            to="/help/"
            className={`nav-left-option ${
              !categories.help ? '' : 'selected-category'
            }`}
          >
            <MdHelpOutline className="nav-left-icon" />
            Help
          </Link>
        </div>
      </div>
      <div className="nav-top">
        <div className="nav-top-mobile">
          <GrMenu className="nav-mi-icon black-icon" onClick={showLeftMenu} />
        </div>
        {/* <div className='nav-user'>
            <UserIcon className='nav-user-icon' />
            <span className='nav-user-name'>
              Welcome,
              <span className='bold'>{name ? name : email}</span>
            </span>
          </div> */}
        <div className="nav-logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className="nav-icons-menu">
          <div className="nav-news-box nav-mi-icon">
            <GoBell className="black-icon" />
            <span className="new-count-icon"></span>
            {/* {unread_messages ? (
                <span className='new-count-icon'>{unread_messages}</span>
              ) : null} */}
          </div>
          <UserAccessList
            user={user}
            profile={profile}
            sharedAccess={sharedAccess}
            switchRoleAccount={switchRoleAccount}
            logout={logout}
          />
        </div>
      </div>
      <ConnectorModal />
    </div>
  );
};

const mapStateToProps = (state) => ({
  connections: state.connections,
  auth: state.auth
});

export default connect(mapStateToProps, { logout, switchRoleAccount })(Navbar);
