export const getActionNewHeaders = (isDevAdmin) => {
  const data = [
    { label: 'ID', key: 'id', width: '80px' },
    { label: 'STATUS', key: 'status', width: '120px' },
    {
      label: 'LAST RUN',
      key: 'run-at',
      text: 'Date and Time of the last action run in the time zone selected in the User settings (by default - Your local time)',
      width: '180px'
    },
    {
      label: 'NEXT RUN',
      key: 'next-run-at',
      text: 'Date and Time of the next action run according to the settled schedule in the time zone selected in the User settings (by default - Your local time)',
      width: '180px'
    },
    {
      label: 'RUN',
      key: 'run',
      text: 'Here you can force action to run manually. Just click on the icon next to the required action',
      width: '100px'
    },
    { label: 'SCHEDULE', key: 'schedule', width: '100px' }
  ];

  if (isDevAdmin) {
    data.push({ label: 'DEV LOGS', key: 'devLogs', width: '100px' });
  }

  return data;
};
