import {
  GET_ALL_SHARED_ACCESS,
  CLEAR_SHARED_ACCESS,
  SET_SHARED_ACCESS_LOADING,
  CHANGE_SHARED_ACCESS,
  DELETE_SHARED_ACCESS,
  CHECK_NEW_USER_TOKEN
} from '../actions/types';

const initialState = {
  records: [],
  invitedUser: null,
  loading: true,
  loadingAccess: false,
  loadingInvitedUser: true
};

export default function sharedAccess(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_SHARED_ACCESS:
      return {
        ...state,
        records: payload,
        loading: false
      };
    case CHANGE_SHARED_ACCESS:
      return {
        ...state,
        records: state.records.map((r) => {
          if (payload._id === r._id) {
            return { ...r, ...payload };
          }
          return r;
        }),
        loadingAccess: false
      };
    case DELETE_SHARED_ACCESS:
      return {
        ...state,
        records: state.records.filter((r) => payload !== r._id),
        loadingAccess: false
      };
    case SET_SHARED_ACCESS_LOADING:
      return {
        ...state,
        loadingAccess: payload
      };
    case CHECK_NEW_USER_TOKEN:
      return {
        ...state,
        invitedUser: payload,
        loadingInvitedUser: false
      };
    case CLEAR_SHARED_ACCESS:
      return initialState;
    default:
      return state;
  }
}
