import { roles } from '../../utils/constants';

export const checkOldConnector = (path) => {
  if (!path) return false;
  const urls = ['/actionsold/', '/feeds/', '/feed/'];
  const found = urls.find((u) => path.includes(u));
  return found ? true : false;
};

export const checkRoleAccess = (role, path) => {
  const CHECK_ROLES = [
    {
      roles: [roles.admin.name, roles.superadmin.name],
      paths: ['/users/', '/shared/']
    },
    { roles: [roles.superadmin.name], paths: ['/profiles/'] }
  ];
  let hasAcces = true;

  CHECK_ROLES.forEach((row) => {
    if (!row.roles.includes(role)) {
      row.paths.forEach((p) => {
        if (path.includes(p)) {
          hasAcces = false;
        }
      });
    }
  });

  return hasAcces;
};
