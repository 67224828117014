import React from 'react';
import classes from './Button.module.css';

export const Button = ({
  children,
  refBtn = null,
  className,
  onClick,
  ...otherProps
}) => {
  return (
    <button
      ref={refBtn}
      className={`${classes.button} ${className || ''}`}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </button>
  );
};
