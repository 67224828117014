import { Fragment } from 'react';
import { connect } from 'react-redux';
import { PageLoader } from '../../../utils/components';

const ActionSettingsOld = ({
  connector: { actionSettings, settingsLoading }
}) => {
  const settings = [];
  for (const key in actionSettings) {
    settings.push({ name: key, value: actionSettings[key] });
  }
  return (
    <Fragment>
      {settingsLoading ? (
        <PageLoader height="70vh" />
      ) : (
        <div className="action-settings-container">
          {settings.map((setting, i) => (
            <div key={`setting-${i}`} className="action-settings-param">
              <span className="action-settings-name">
                {setting.name
                  ? setting.name
                      .split('_')
                      .map(
                        (word) =>
                          word.charAt(0).toUpperCase() + word.substring(1)
                      )
                      .join(' ')
                  : ''}
                :
              </span>
              <span className="action-settings-value">{setting.value}</span>
            </div>
          ))}
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  connector: state.connector
});
export default connect(mapStateToProps)(ActionSettingsOld);
