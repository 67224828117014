import { connect } from 'react-redux';
import { closeConnectorModal } from '../../actions/connector';
import { Close } from '@material-ui/icons';

const ModalLog = ({ modal: { data, isOpen }, closeConnectorModal }) => {
  const checkIfModal = (e) => {
    if (e.target.classList) {
      if (e.target.classList.contains('modal')) {
        closeConnectorModal();
      }
    }
  };

  const closeModal = () => {
    closeConnectorModal();
  };

  const logType = data
    ? +data.error === 1
      ? 'Error'
      : +data.debug === 1
      ? 'Debug'
      : ''
    : '';

  return (
    <div
      className='modal'
      onClick={checkIfModal}
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className='modal-container'>
        <div className='modal-header'>
          <p className='modal-title'>Log Message</p>
          <Close
            onClick={closeModal}
            className='actions-icon'
            fontSize='medium'
          />
        </div>
        <div className='modal-body'>
          <div className='modal-body-header'>
            <div className='modal-body-header-param'>
              <span className='modal-body-header-name'>Type:</span>
              <span
                className={`modal-body-header-value ${
                  logType === 'Error' ? 'error-text' : ''
                }`}
              >
                {logType ? logType : 'Unknown'}
              </span>
            </div>
            <div className='modal-body-header-param'>
              <span className='modal-body-header-name'>Date:</span>
              <span className='modal-body-header-value'>
                {data ? data.created_at : ''}
              </span>
            </div>
          </div>
          <div className='modal-body-param '>
            <span className='modal-body-header-name'>Message:</span>
            <span className='modal-body-message'>
              {data ? data.message : ''}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  modal: state.connector.modal,
});
export default connect(mapStateToProps, { closeConnectorModal })(ModalLog);
