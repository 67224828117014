import React, { useMemo, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import { GrUserSettings } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { roles } from '../../../utils/constants';
import { FiLogOut } from 'react-icons/fi';
import classes from '../Navbar.module.css';

export const UserAccessList = ({
  user,
  profile,
  sharedAccess,
  switchRoleAccount,
  logout
}) => {
  const [isOpen, setOpen] = useState(false);
  const rolesList = useMemo(() => {
    if (profile?._id !== user?.account && Array.isArray(sharedAccess)) {
      for (let i = 0; i < sharedAccess.length; i++) {
        if (sharedAccess[i]?.account === profile?._id) {
          return sharedAccess[i]?.roles;
        }
      }
    }
    return user?.roles;
  }, [user, profile, sharedAccess]);

  const switchRole = async (role) => {
    const res = await switchRoleAccount({ role });
    if (res) {
      window.location.reload();
    }
  };

  const switchAccount = async (id) => {
    const res = await switchRoleAccount({ account: id });
    if (res) {
      window.location.reload();
    }
  };

  const exit = () => {
    logout();
  };

  return (
    <div className={classes.userBox}>
      <Link to="/user/" className={classes.userContainer}>
        <GrUserSettings className="nav-mi-icon black-icon" />
        <div className={classes.userData}>
          <p className={classes.userDataInfo}>{user?.name || user?.email}</p>
          <p className={classes.userDataInfo}>
            {profile?.company || 'Main Account'} - {roles[user?.role]?.label}
          </p>
        </div>
      </Link>
      <div
        className={classes.userContainerMobile}
        onClick={() => setOpen((prev) => !prev)}
      >
        <GrUserSettings className="nav-mi-icon black-icon" />
      </div>
      <ul
        className={`${classes.userAccessList} ${
          isOpen ? classes.userListActive : ''
        }`}
      >
        <li className={classes.hideMobile}>
          <Link
            to="/user/"
            onClick={exit}
            className={classes.userAccessListRow}
          >
            <p>User Page</p>
            <p className={classes.userAccessListIconBox}>
              <GrUserSettings className={classes.userAccessListIcon} />
            </p>
          </Link>
        </li>
        <li className={classes.userAccessListDevider}></li>
        <li>
          <Link to="/" onClick={exit} className={classes.userAccessListRow}>
            <p>Log Out</p>
            <p className={classes.userAccessListIconBox}>
              <FiLogOut className={classes.userAccessListIcon} />
            </p>
          </Link>
        </li>
        <li className={classes.userAccessListDevider}></li>
        <li className={classes.userAccessListName}>
          {profile?.company || 'Main Account'}
        </li>
        {rolesList?.map((role) => (
          <li
            key={role}
            className={classes.userAccessListRow}
            onClick={() => switchRole(role)}
          >
            <p>{roles[role]?.label}</p>
            {role === user?.role && (
              <p className={classes.userAccessListIconBox}>
                <FiCheck className={classes.userAccessListIconCheck} />
              </p>
            )}
          </li>
        ))}
        {sharedAccess.length > 0 && (
          <>
            <li className={classes.userAccessListDevider}></li>
            <li className={classes.userAccessListName}>
              Switch to another Account
            </li>
            {sharedAccess.map((access) => {
              const isCurrent = access.account === profile._id;
              return (
                <li
                  key={access._id}
                  className={classes.userAccessListRow}
                  onClick={() =>
                    switchAccount(isCurrent ? user.account : access.account)
                  }
                >
                  {isCurrent
                    ? user.accountName || 'Main Account'
                    : access?.profile?.[0]?.company}
                </li>
              );
            })}
          </>
        )}
      </ul>
    </div>
  );
};
