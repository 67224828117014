import { lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import ActionOld from '../../pages/ActionOld/ActionOld';
import ActionNew from '../../pages/ActionNew/ActionNew';
import Page404 from '../layout/Page404';
import PublicRoute from './PublicRoute';
//import Landing from '../layout/Landing';

const Login = lazy(() => import('../../pages/Login/Login'));
const Landing = lazy(() => import('../layout/Landing/Landing'));
const Dashboard = lazy(() => import('../layout/Dashboard/Dashboard'));
const UserPage = lazy(() => import('../../pages/UserPage/UserPage'));
const ActionCenterNew = lazy(() =>
  import('../../pages/ActionCenterNew/ActionCenterNew')
);
const ActionCenterOld = lazy(() =>
  import('../../pages/ActionCenterOld/ActionCenterOld')
);
const ActionGroupOld = lazy(() =>
  import('../../pages/ActionGroupOld/ActionGroupOld')
);
const ActionGroupNew = lazy(() =>
  import('../../pages/ActionGroupNew/ActionGroupNew')
);
const FeedsCenterOld = lazy(() => import('../layout/FeedsCenterOld'));
const FeedOld = lazy(() => import('../layout/FeedOld'));
const Settings = lazy(() => import('../layout/Settings'));
const UsersPage = lazy(() => import('../../pages/UsersPage/UsersPage'));
const HelpCasePage = lazy(() =>
  import('../../pages/HelpCasePage/HelpCasePage')
);
const ConnectionSettings = lazy(() =>
  import('../settings/connections/ConnectionSettings')
);
const AddConnection = lazy(() =>
  import('../settings/connections/AddConnection')
);
const AddAction = lazy(() => import('../settings/connections/AddAction'));
const FeedSettings = lazy(() => import('../settings/connections/FeedSettings'));
const AccountsPage = lazy(() => import('../settings/connections/AccountsPage'));
const AddProfileUser = lazy(() =>
  import('../../pages/AddProfileUserPage/AddProfileUserPage')
);
const EditProfileUser = lazy(() =>
  import('../../pages/EditProfileUserPage/EditProfileUserPage')
);
const LogsPage = lazy(() => import('../layout/LogsPage/LogsPage'));
const Profiles = lazy(() => import('../../pages/Profiles/Profiles'));
const SharedAccessPage = lazy(() =>
  import('../../pages/SharedAccessPage/SharedAccessPage')
);
const ResetPassword = lazy(() =>
  import('../../pages/ResetPassword/ResetPassword')
);
const SendResetEmail = lazy(() =>
  import('../../pages/SendResetEmail/SendResetEmail')
);

const CreateInviteUserPage = lazy(() =>
  import('../../pages/CreateInviteUserPage/CreateInviteUserPage')
);

const AppRoutes = () => {
  return (
    <Router>
      <Switch>
        <PublicRoute exact path="/" component={Landing} />
        <PublicRoute exact path="/login/" component={Login} />
        <PublicRoute
          exact
          path="/resetPassword/:token"
          component={ResetPassword}
        />
        <PublicRoute
          exact
          path="/createUser/:token"
          component={CreateInviteUserPage}
        />
        <PublicRoute exact path="/resetPassword/" component={SendResetEmail} />
        {/* <Route exact path='/register' component={CreateProfile} /> */}
        <PrivateRoute exact path="/dashboard/" component={Dashboard} />
        <PrivateRoute exact path="/user/" component={UserPage} />
        <PrivateRoute exact path="/settings/" component={Settings} />
        <PrivateRoute exact path="/actionsnew/" component={ActionCenterNew} />
        <PrivateRoute exact path="/actionsold/" component={ActionCenterOld} />
        <PrivateRoute
          exact
          path={'/actionsold/:name/'}
          component={ActionGroupOld}
        />
        <PrivateRoute exact path="/feeds/" component={FeedsCenterOld} />
        <PrivateRoute exact path={'/feeds/:id/'} component={FeedOld} />
        <PrivateRoute
          exact
          path={'/actionsnew/:id/'}
          component={ActionGroupNew}
        />
        <PrivateRoute
          exact
          path={'/actionsold/:name/action/:id/'}
          component={ActionOld}
        />
        <PrivateRoute
          exact
          path={'/actionsold/:name/action/:id/logs/'}
          componentProps={<ActionOld tabActive={0} />}
        />
        <PrivateRoute
          exact
          path={'/actionsold/:name/action/:id/settings/'}
          componentProps={<ActionOld tabActive={1} />}
        />
        <PrivateRoute
          exact
          path={'/actionsnew/:id/action/:id/'}
          component={ActionNew}
        />
        <PrivateRoute
          exact
          path={'/actionsnew/:id/action/:id/logs/'}
          componentProps={<ActionNew tabActive={0} />}
        />
        <PrivateRoute
          exact
          path={'/actionsnew/:id/action/:id/settings/'}
          componentProps={<ActionNew tabActive={1} />}
        />
        <PrivateRoute
          exact
          path={'/actionsnew/:id/action/:id/description/'}
          componentProps={<ActionNew tabActive={2} />}
        />
        <PrivateRoute
          exact
          path={'/settings/connection/:id/'}
          component={ConnectionSettings}
        />
        <PrivateRoute
          exact
          path={'/settings/add/connection/'}
          component={AddConnection}
        />
        <PrivateRoute
          exact
          path={'/settings/add/action/'}
          component={AddAction}
        />
        <PrivateRoute
          exact
          path={'/settings/feed/:id/'}
          component={FeedSettings}
        />
        <PrivateRoute
          exact
          path={'/settings/account/:id/'}
          component={AccountsPage}
        />
        <PrivateRoute exact path={'/users/'} component={UsersPage} />
        <PrivateRoute exact path={'/users/add/'} component={AddProfileUser} />
        <PrivateRoute
          exact
          path={'/users/edit/:id/'}
          component={EditProfileUser}
        />
        <PrivateRoute exact path={'/profiles/'} component={Profiles} />
        <PrivateRoute exact path={'/shared/'} component={SharedAccessPage} />
        <PrivateRoute exact path={'/help/'} component={HelpCasePage} />
        <PrivateRoute exact path={'/logs/'} component={LogsPage} />
        <Route path="*" component={Page404} />
      </Switch>
    </Router>
  );
};

export default AppRoutes;
