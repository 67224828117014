import { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { closeConnectorModal } from '../../../actions/connector';
import {
  updateSharedAccess,
  createSharedAccess,
  getAllSharedAccess,
  deleteSharedAccess
} from '../../../actions/sharedAccess';
import { Close } from '@material-ui/icons';
import { Input } from '../../../UI/Input/Input';
import { roles } from '../../../utils/constants';
import { Select } from '../../../UI/Select/Select';
import classes from './ModalInvite.module.css';

const ModalInvite = ({
  modal: { id, data, isOpen },
  sharedAccess: { loadingAccess },
  closeConnectorModal,
  updateSharedAccess,
  createSharedAccess,
  getAllSharedAccess,
  deleteSharedAccess
}) => {
  const roleOptions = useMemo(() => [roles.admin, roles.support], []);
  const [formData, setFormData] = useState({
    email: id ? data?.email : '',
    roles: id ? roleOptions.filter((r) => data?.roles?.includes?.(r.name)) : []
  });

  const onChangeForm = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const setRoles = (value) => setFormData({ ...formData, roles: value });

  const checkIfModal = (e) => {
    if (e.target.classList) {
      if (e.target.classList.contains('modal')) {
        closeConnectorModal();
      }
    }
  };

  const closeModal = () => {
    closeConnectorModal();
  };

  const sendInvite = async () => {
    const res = await createSharedAccess({
      email: formData.email,
      roles: formData.roles.map((r) => r.name)
    });
    if (res) {
      closeConnectorModal();
      if (res._id) {
        getAllSharedAccess();
      }
    }
  };

  const updateAccess = async () => {
    if (id) {
      const res = await updateSharedAccess(id, {
        roles: formData.roles.map((r) => r.name)
      });
      if (res) {
        closeConnectorModal();
      }
    }
  };

  const removeAccess = async () => {
    if (id) {
      const res = await deleteSharedAccess(id);
      if (res) {
        closeConnectorModal();
      }
    }
  };

  return (
    <div
      className="modal"
      onClick={checkIfModal}
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className={`modal-container ${classes.container}`}>
        <div className="modal-header">
          <h1 className="modal-title">
            {id ? 'Edit User Access' : 'Invite User'}
          </h1>
          <Close
            onClick={closeModal}
            className="actions-icon"
            fontSize="medium"
          />
        </div>
        <div className={`modal-body ${classes.formContainer}`}>
          <div className={classes.formBox}>
            <ul className={classes.list}>
              {id && (
                <li className={classes.formRow}>
                  <p className={classes.formRowName}>User Name</p>
                  <p className={classes.formRowValue}>{data?.name}</p>
                </li>
              )}
              <li className={classes.formRow}>
                <p className={classes.formRowName}>User Email</p>
                <Input
                  className="settings-edit-input"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={onChangeForm}
                  disabled={id ? true : false}
                  hideError
                />
              </li>
              <li className={classes.formRow}>
                <p className={classes.formRowName}>Roles:</p>
                <Select
                  containerClass="settings-edit-input"
                  options={roleOptions}
                  value={formData.roles}
                  onChange={setRoles}
                  valueKey="name"
                  labelKey="label"
                  multiple
                />
              </li>
            </ul>
            <div className={classes.btnBox}>
              {id ? (
                <>
                  <button
                    className="settings-btn settings-update-btn"
                    onClick={updateAccess}
                    disabled={loadingAccess}
                  >
                    Update
                  </button>
                  <button
                    className="settings-btn settings-delete-btn"
                    onClick={removeAccess}
                    disabled={loadingAccess}
                  >
                    Delete
                  </button>
                </>
              ) : (
                <button
                  className="settings-btn"
                  onClick={sendInvite}
                  disabled={loadingAccess}
                >
                  Send Invitation
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  modal: state.connector.modal,
  sharedAccess: state.sharedAccess
});

export default connect(mapStateToProps, {
  closeConnectorModal,
  updateSharedAccess,
  createSharedAccess,
  getAllSharedAccess,
  deleteSharedAccess
})(ModalInvite);
