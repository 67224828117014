import React from 'react';
import BreadcrumbsPath from '../breadcrumbs/BreadcrumbsPath';
import ActionSearch from '../modules/ActionSearch/ActionSearch';
// import { BreadcrumbsPath } from '../../utils/components';

const Breadcrumbs = ({ path, component, loading }) => {
  return (
    <section className='breadcrumbs-container'>
      {loading ? (
        <div className='search-container skeleton-box'></div>
      ) : (
        <BreadcrumbsPath path={path} />
      )}
      <ActionSearch />
      {component}
    </section>
  );
};

export default Breadcrumbs;
