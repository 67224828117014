import { useState, Fragment, useMemo } from 'react';
import { InfoTooltip, StringToJSX } from '../../../utils/components';
import { dbLogTypes, getDbLogMessage, roles } from '../../../utils/constants';
import { connect } from 'react-redux';
import {
  openConnectorModal,
  closeConnectorModal
} from '../../../actions/connector';

const AccountSettings = ({
  user: { role },
  groups,
  data,
  openConnectorModal,
  closeConnectorModal,
  action,
  record
}) => {
  const groupName = useMemo(() => {
    let group = data?.groups ? data?.groups[0] : null;
    group = groups.find((g) => +g.id === +group);
    return group?.name;
  }, [data, groups]);

  const values = data.settings
    ? data.settings.values
      ? data.settings.values
      : null
    : null;
  const headers = data.settings
    ? data.settings.headers
      ? data.settings.headers
      : null
    : null;
  const info = data.settings
    ? data.settings.labels
      ? data.settings.labels
      : null
    : null;

  const showOverlay = (info) => {
    //Temp condition
    if (role === roles.support.name) return true;
    if (!info) return false;
    if (info.adminAccess && role !== roles.admin.name) return true;
    return false;
  };

  let initialState = {};

  try {
    initialState = JSON.parse(JSON.stringify(values));
  } catch (error) {
    console.log(error);
  }

  const [state, setState] = useState(initialState);
  const [isChanged, setChanged] = useState(false);

  const changeState = ({ key1, key2, index1, index2, value }) => {
    let newState = null;
    try {
      newState = JSON.parse(JSON.stringify(state));
    } catch (error) {
      console.log(error);
    }

    if (newState) {
      if (index2 !== undefined) {
        newState[key1][index1].values[index2][key2] = value;
      } else {
        newState[key1][index1][key2] = value;
      }
      setState(newState);
      if (!isChanged) setChanged(true);
    }
  };

  const cancelChanges = (e) => {
    setState(initialState);
    setChanged(false);
  };

  const updateAccount = (e) => {
    openConnectorModal({
      id: data.id,
      type: 'confirmation',
      data: {
        message: `Are you sure you want to update this ${record}?`,
        action: (
          <button
            className="modal-btn modal-delete-btn"
            onClick={() => {
              action({
                id: data.id,
                values: { settings: state },
                logData: {
                  actionId: +data.id,
                  type: dbLogTypes.settings,
                  message: getDbLogMessage(
                    `UPDATE ${record} Settings`,
                    data.name,
                    groupName
                  ),
                  isNewConnector: true
                }
              });
              closeConnectorModal();
              setChanged(false);
            }}
          >
            OK
          </button>
        )
      }
    });
  };

  return (
    <div className="action-settings-container">
      {isChanged ? (
        <div className="action-settings-btn-box">
          <button
            className="settings-btn settings-update-btn"
            onClick={updateAccount}
          >
            Update
          </button>
          <button
            className="settings-btn settings-delete-btn"
            onClick={cancelChanges}
          >
            Cancel
          </button>
        </div>
      ) : null}
      {values
        ? Object.keys(values).map((key, i) => (
            <div key={`settings-${i}`} className="action-settings-box">
              {/* {showOverlay(info[key]) ? (
                <div className='action-settings-box-overlay'></div>
              ) : null} */}
              <h2 className="action-settings-header">
                {info[key] ? (info[key].label ? info[key].label : key) : key}
                {info[key] ? (
                  info[key].text ? (
                    <InfoTooltip text={info[key].text} />
                  ) : info[key].html ? (
                    <InfoTooltip>
                      <StringToJSX domString={info[key].html} />
                    </InfoTooltip>
                  ) : null
                ) : null}
              </h2>
              <div
                style={{
                  gridTemplateColumns:
                    headers[key].length === 2
                      ? `210px 350px`
                      : headers[key].length === 1
                      ? '300px'
                      : `repeat(${headers[key].length}, 180px)`
                }}
                className="action-settings-list-header"
              >
                {headers[key]?.map((h, j) => (
                  <div
                    key={`list-header-cell-${j}`}
                    className="action-settings-list-header-cell"
                  >
                    {h.label}
                    {h.text ? (
                      <InfoTooltip text={h.text} />
                    ) : h.html ? (
                      <InfoTooltip>
                        <StringToJSX domString={h.html} />
                      </InfoTooltip>
                    ) : null}
                  </div>
                ))}
              </div>
              <ul className="action-settings-list">
                {values[key]?.map((level1, j) => (
                  <li
                    style={{
                      gridTemplateColumns:
                        headers[key].length === 2
                          ? `210px 350px`
                          : headers[key].length === 1
                          ? '300px'
                          : `repeat(${headers[key].length}, 180px)`
                    }}
                    className="action-settings-row"
                    key={`row-${j}`}
                  >
                    {typeof level1 !== 'object' ? (
                      <span className="action-settings-row-name">{level1}</span>
                    ) : level1.values ? (
                      <Fragment>
                        <h3 className="action-settings-header2">
                          {info[level1.key]
                            ? info[level1.key].label
                              ? info[level1.key].label
                              : level1.key
                            : level1.key}
                          {info[level1.key] ? (
                            info[level1.key].text ? (
                              <InfoTooltip text={info[level1.key].text} />
                            ) : info[level1.key].html ? (
                              <InfoTooltip>
                                <StringToJSX
                                  domString={info[level1.key].html}
                                />
                              </InfoTooltip>
                            ) : null
                          ) : null}
                        </h3>
                        {headers[level1.key] ? (
                          <div className="action-settings-list2">
                            <div>
                              {level1.values.map((level2, k) => (
                                <div
                                  key={`list-header-cell2-${k}`}
                                  style={{
                                    gridTemplateColumns:
                                      headers[level1.key]?.length === 2
                                        ? `120px 230px`
                                        : `repeat(${
                                            headers[level1.key]?.length
                                          }, 120px)`
                                  }}
                                  className="action-settings-row2"
                                >
                                  {headers[level1.key]?.map((h) => (
                                    <Fragment key={`list-row-cell2-${h.key}`}>
                                      {h.editable ? (
                                        showOverlay(info[key]) ? (
                                          <input
                                            type="text"
                                            className="actions-settings-input"
                                            defaultValue={level2[h.key]}
                                          />
                                        ) : (
                                          <input
                                            type="text"
                                            className="actions-settings-input"
                                            value={
                                              state[key]?.[j]?.values[k]?.[
                                                h.key
                                              ]
                                            }
                                            onChange={(e) => {
                                              changeState({
                                                key1: key,
                                                index1: j,
                                                key2: h.key,
                                                index2: k,
                                                value: e.target.value
                                              });
                                            }}
                                          />
                                        )
                                      ) : (
                                        <span className="action-settings-row-name">
                                          {info[h.key]
                                            ? info[h.key].label
                                              ? info[h.key].label
                                              : level2[h.key]
                                            : level2[h.key]}
                                        </span>
                                      )}
                                    </Fragment>
                                  ))}
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : null}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {headers[key]?.map((h, k) => (
                          <Fragment key={`list-row-cell1-${k}`}>
                            {h.editable ? (
                              showOverlay(info[key]) ? (
                                h.label === 'Expression' ? (
                                  <textarea
                                    className="actions-settings-textarea"
                                    defaultValue={level1[h.key]}
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    className="actions-settings-input"
                                    defaultValue={level1[h.key]}
                                  />
                                )
                              ) : h.label === 'Expression' ? (
                                <textarea
                                  className="actions-settings-textarea"
                                  value={state[key]?.[j]?.[h.key]}
                                  onChange={(e) => {
                                    changeState({
                                      key1: key,
                                      index1: j,
                                      key2: h.key,
                                      value: e.target.value
                                    });
                                  }}
                                />
                              ) : (
                                <input
                                  type="text"
                                  className="actions-settings-input"
                                  value={state[key]?.[j]?.[h.key]} //{level1[h.key]}
                                  onChange={(e) => {
                                    changeState({
                                      key1: key,
                                      index1: j,
                                      key2: h.key,
                                      value: e.target.value
                                    });
                                  }}
                                />
                              )
                            ) : (
                              <span className="action-settings-row-name">
                                {level1.label ? level1.label : level1[h.key]}
                              </span>
                            )}
                          </Fragment>
                        ))}
                      </Fragment>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))
        : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  groups: state.connections.groupsNew
});

export default connect(mapStateToProps, {
  openConnectorModal,
  closeConnectorModal
})(AccountSettings);
