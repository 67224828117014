import { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { PageLoader } from '../../utils/components';
import { checkScore } from '../../actions/publicActions';
import { RecaptchaBadge } from '../../widgets/RecaptchaBadge/RecaptchaBadge';

const PublicRoute = ({
  component: Component,
  componentProps,
  checkScore,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<PageLoader height="90vh" />}>
          {componentProps ? componentProps : <Component {...props} />}
          <RecaptchaBadge checkScore={checkScore} />
        </Suspense>
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { checkScore })(PublicRoute);
