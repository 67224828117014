import {
  SET_PUBLIC_EMAIL_LOADING,
  GET_CAPTCHA_SCORE,
  CHECK_RESET_TOKEN
} from '../actions/types';

const initialState = {
  score: 1,
  emailLoading: false,
  resetUser: null,
  resetTokenLoading: true
};

export default function publicReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_PUBLIC_EMAIL_LOADING:
      return {
        ...state,
        emailLoading: payload
      };
    case GET_CAPTCHA_SCORE:
      return {
        ...state,
        score: payload
      };
    case CHECK_RESET_TOKEN:
      return {
        ...state,
        resetUser: payload,
        resetTokenLoading: false
      };

    default:
      return state;
  }
}
