import { useEffect } from 'react';
import recaptcha from '../../img/recaptcha.png';
import classes from './RecaptchaBadge.module.css';

export const RecaptchaBadge = ({ checkScore }) => {
  useEffect(() => {
    if (!window.grecaptcha) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`;
      script.addEventListener('load', () => {
        if (window.grecaptcha) {
          window.grecaptcha.ready(() => {
            window.grecaptcha
              .execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'login' })
              .then((token) => {
                checkScore(token);
              });
          });
        }
      });
      document.body.appendChild(script);
    }
  }, [checkScore]);

  return (
    <div className={classes.recaptcha}>
      <img src={recaptcha} alt="recaptcha" />
      <p>
        This site is protected by reCAPTCHA and the Google{' '}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer noopener"
        >
          Privacy Policy
        </a>{' '}
        and{' '}
        <a
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noreferrer noopener"
        >
          Terms of Service
        </a>{' '}
        apply.{' '}
      </p>
    </div>
  );
};
