import { connect } from 'react-redux';
import { closeConnectorModal } from '../../actions/connector';
import { IoWarningOutline } from 'react-icons/io5';

const ModalConfirmation = ({
  modal: { data, isOpen },
  closeConnectorModal,
}) => {
  const checkIfModal = (e) => {
    if (e.target.classList) {
      if (e.target.classList.contains('modal')) {
        closeConnectorModal();
      }
    }
  };

  const closeModal = () => {
    closeConnectorModal();
  };

  return (
    <div
      className='modal'
      onClick={checkIfModal}
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className='modal-container-confirm'>
        <IoWarningOutline className='modal-confirm-icon' />
        <p className='modal-confirm-message'>{data.message}</p>
        <div className='modal-confirm-buttons'>
          {data.action}
          <button className='modal-btn modal-cancel-btn' onClick={closeModal}>
            CANCEL
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  modal: state.connector.modal,
});
export default connect(mapStateToProps, { closeConnectorModal })(
  ModalConfirmation
);
