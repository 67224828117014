import { useMemo } from 'react';
import { connect } from 'react-redux';
import {
  dbLogTypes,
  getActionStatus,
  getDbLogMessage,
  isBusyAction,
  recalcTime,
  roles
} from '../../../utils/constants';
import { InfoTooltip, PlayBtn, StopBtn } from '../../../utils/components';
import {
  runActionNew,
  openConnectorModal,
  stopAction
} from '../../../actions/connector';
import { BiTime } from 'react-icons/bi';
import { formTableRow } from '../../../utils/tables';
import { getActionNewHeaders } from '../ActionNew.data';
import { FaDev } from 'react-icons/fa';

const ActionHeaderNew = ({
  connector: { actionsSetRun, actionsSetStop },
  groups,
  auth: { user, profile },
  actionData,
  runActionNew,
  openConnectorModal,
  stopAction
}) => {
  const isDevAdmin = useMemo(
    () => user.role === roles.admin.name && profile.devLogsLink,
    [user, profile]
  );
  const actionNewHeaders = useMemo(
    () => getActionNewHeaders(isDevAdmin),
    [isDevAdmin]
  );
  const actionStatus = useMemo(
    () => (actionData ? getActionStatus(actionData.status) : null),
    [actionData]
  );
  const groupName = useMemo(() => {
    let group = actionData?.groups ? actionData?.groups[0] : null;
    group = groups.find((g) => +g.id === +group);
    return group?.name;
  }, [actionData, groups]);

  const runCurrentAction = (e) => {
    const actionId = e.currentTarget.getAttribute('data-id');
    runActionNew(actionId, {
      actionId: +actionId,
      type: dbLogTypes.action,
      message: getDbLogMessage('RUN', actionData?.name, groupName),
      isNewConnector: true
    });
  };

  const stopCurrentAction = (e) => {
    const taskId = e.currentTarget.getAttribute('data-task');
    const actionId = e.currentTarget.getAttribute('data-action');
    if (taskId && actionId) {
      stopAction({
        taskId,
        actionId,
        logData: {
          actionId: +actionId,
          type: dbLogTypes.action,
          message: getDbLogMessage('STOP', actionData?.name, groupName),
          isNewConnector: true
        }
      });
    }
  };

  if (!actionData) return null;

  return (
    <div className="action-header-box">
      <div
        className="standard-table-header"
        style={{
          gridTemplateColumns: formTableRow(actionNewHeaders)
        }}
      >
        {actionNewHeaders.map((h, i) => (
          <div
            key={`action-table-header-${i}`}
            className="actions-table-header-cell"
          >
            {h.label}
            {h.text ? <InfoTooltip text={h.text} /> : null}
          </div>
        ))}
      </div>
      <div className="actions-table-body">
        <div
          className="standard-table-row"
          style={{
            gridTemplateColumns: formTableRow(actionNewHeaders)
          }}
        >
          <div className="actions-table-body-cell">{actionData.id}</div>
          <div className="actions-table-body-cell">
            {actionStatus ? actionStatus.img : 'Ready'}
          </div>
          <div className="actions-table-body-cell">
            {recalcTime(actionData['run-at'], user)}
          </div>
          <div className="actions-table-body-cell">
            {recalcTime(actionData['next-run-at'], user)}
          </div>
          <div className="actions-table-body-cell">
            <PlayBtn
              id={actionData.id}
              status={actionData.status}
              actionsSetRun={actionsSetRun}
              runFunction={runCurrentAction}
            />
            <StopBtn
              taskId={
                isBusyAction(actionData.status) && actionData['last-task-id']
                  ? actionData['last-task-id']
                  : ''
              }
              actionId={actionData.id}
              status={actionData.status}
              actionsSetStop={actionsSetStop}
              runFunction={stopCurrentAction}
              role={user.role}
            />
          </div>
          <div className="actions-table-body-cell">
            <BiTime
              className={`active-icon ${
                actionData['schedule-status'] ? '' : 'warning-icon'
              }`}
              onClick={() =>
                openConnectorModal({
                  id: actionData.id,
                  type: 'schedule',
                  data: actionData
                })
              }
            />
          </div>
          {isDevAdmin && (
            <div className="actions-table-body-cell">
              <a
                className="primary-link"
                href={`${profile.devLogsLink}${actionData.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <FaDev className="action-tab-icon" />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  connector: state.connector,
  auth: state.auth,
  groups: state.connections.groupsNew
});
export default connect(mapStateToProps, {
  runActionNew,
  openConnectorModal,
  stopAction
})(ActionHeaderNew);
