import { useState, useEffect, useMemo } from 'react';
import ScheduleMethods from './ScheduleMethods';
import { MenuItem, Select } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { getTimezoneHours } from '../../../utils/constants';

const Monthly = ({ timezone, setCron, defaultCron }) => {
  const [formData, setFormData] = useState({
    day: ScheduleMethods.getCronMonthly(
      defaultCron,
      getTimezoneHours(timezone)
    ),
    time: ScheduleMethods.getCronTime(defaultCron, getTimezoneHours(timezone)),
  });

  const { day, time } = formData;

  const onChangeForm = (e) => {
    if (e.target) {
      setFormData({
        ...formData,
        day: e.target.value,
      });
    } else {
      setFormData({ ...formData, time: e });
    }
  };

  useEffect(() => {
    if (day.length) {
      setCron(
        ScheduleMethods.calculateMonthlyCron({
          day,
          time,
          timezone: getTimezoneHours(timezone),
        })
      );
    }
  }, [timezone, day, time, setCron]);

  const MenuProps = useMemo(
    () => ({
      classes: { paper: 'select-list' },
      variant: 'menu',
      getContentAnchorEl: null,
    }),
    []
  );

  const minTime = useMemo(() => {
    const date = new Date();
    date.setHours(
      ScheduleMethods.getMinHour({
        day,
        timezone: getTimezoneHours(timezone),
        isMin: true,
      }),
      0
    );
    return date;
  }, [day, timezone]);

  const maxTime = useMemo(() => {
    const date = new Date();
    date.setHours(
      ScheduleMethods.getMinHour({
        day,
        timezone: getTimezoneHours(timezone),
        isMin: false,
      }),
      59
    );
    return date;
  }, [day, timezone]);

  //Check min and max time value
  useEffect(() => {
    if (time < minTime) {
      setFormData({ ...formData, time: minTime });
    }

    if (time > maxTime) {
      setFormData({ ...formData, time: maxTime });
    }
  }, [formData, time, minTime, maxTime]);

  return (
    <ul>
      <li className='schedule-main-row schedule-subrow'>
        <p className='schedule-row-name bold'>
          Repeat each selected day of month
        </p>
        <Select
          multiple
          classes={{
            root: 'settings-edit-select',
          }}
          MenuProps={MenuProps}
          value={day}
          onChange={onChangeForm}
          className='schedule-time-box'
          variant='outlined'
        >
          {ScheduleMethods.everyDayMonth().map((e, i) => (
            <MenuItem key={`every-day-month-${i}`} value={e.value}>
              {e.label}
            </MenuItem>
          ))}
        </Select>
      </li>
      <li className='schedule-main-row schedule-subrow'>
        <p className='schedule-row-name bold'>Time</p>
        <div className='schedule-time-box'>
          <DatePicker
            selected={time}
            onChange={onChangeForm}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={1}
            timeCaption='Time'
            dateFormat='HH:mm'
            timeFormat='HH:mm'
            className='schedule-time-picker'
            minTime={minTime}
            maxTime={maxTime}
          />
        </div>
        <AiOutlineClockCircle className='schedule-time-icon' />
      </li>
    </ul>
  );
};

export default Monthly;
