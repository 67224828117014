import { useLocation, Link } from 'react-router-dom';
import { BiError } from 'react-icons/bi';
import { cutString } from '../../utils/services';

const Page404 = () => {
  const location = useLocation().pathname;
  const token = localStorage.getItem('token');

  return (
    <div className="not-found-box">
      <div className="not-found-header">
        <BiError className="not-found-icon" />
        <h1 className="not-found-message">
          Sorry, we couldn't find{' '}
          <span className="not-found-url">{cutString(location, 100)}</span> page
        </h1>
      </div>
      <Link to={token ? '/dashboard/' : '/'} className="not-found-btn">
        {token ? 'Dashboard' : 'Home'}
      </Link>
    </div>
  );
};

export default Page404;
