import React, { useMemo, Fragment } from 'react';
import { ReactComponent as PhotoIcon } from '../img/stub_icon.svg';
import PuffLoader from 'react-spinners/PuffLoader';
import { Tooltip, Fade } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { disableColor, primaryColor, isBusyAction, roles } from './constants';

import { PlayArrow } from '@material-ui/icons';
import { RiStopFill } from 'react-icons/ri';

export const AvatarImage = ({ src }) =>
  useMemo(
    () => (
      <img
        src={src}
        alt='avatar'
        className='responsive round-image nav-avatar'
      />
    ),
    [src]
  );

export const MainCheckbox = ({
  id,
  size,
  onChange,
  active,
  data,
  className,
  disabled,
}) => (
  <Fragment>
    <input
      type='checkbox'
      id={id}
      className={className}
      checked={active}
      onChange={onChange}
      disabled={disabled}
      data-id={data ? data.id : ''}
      data-name={data ? data.name : ''}
      data-value={data ? data.value : ''}
    />
    <label htmlFor={id} style={{ '--d': size }}>
      <svg viewBox='0,0,50,50'>
        <path d='M5 30 L 20 45 L 45 5'></path>
      </svg>
    </label>
  </Fragment>
);

export const OnlineIcon = ({ display }) => (
  <div style={{ display }} className='online-icon-box'>
    <span className='online-icon'></span>
    <p className='online-icon-text'>Online</p>
  </div>
);

export const TableSceleton = () => (
  <div className='table-skeleton-box'>
    <div className='table-loading-container skeleton-box'></div>
    <div className='table-loading-container skeleton-box'></div>
    <div className='table-loading-container skeleton-box'></div>
    <div className='table-loading-container skeleton-box'></div>
    <div className='table-loading-container skeleton-box'></div>
    <div className='table-loading-container skeleton-box'></div>
    <div className='table-loading-container skeleton-box'></div>
    <div className='table-loading-container skeleton-box'></div>
    <div className='table-loading-container skeleton-box'></div>
  </div>
);

export const RowSceleton = () => (
  <div className='row-skeleton-box'>
    <div className='table-loading-container skeleton-box'></div>
    <div className='table-loading-container skeleton-box'></div>
    <div className='table-loading-container skeleton-box'></div>
  </div>
);

export const ListSceleton = () => (
  <div className='list-skeleton-box '>
    <div className='list-loading-container skeleton-box'></div>
    <div className='list-loading-container skeleton-box'></div>
    <div className='list-loading-container skeleton-box'></div>
  </div>
);

export const NoDataItem = ({ text }) => (
  <div className='advertising-nodata-container'>
    <PhotoIcon className='dashboard-sales-icon' />
    <p className='advertising-nodata-text'>{text}</p>
  </div>
);

export const PageLoader = ({ height }) => {
  return (
    <div
      style={{ height: height ? height : '' }}
      className='spinner-container-page'
    >
      <PuffLoader color={'#f57f20'} />
    </div>
  );
};

export const PlayBtn = ({ id, status, actionsSetRun, runFunction }) => {
  if (isBusyAction(status)) return null;

  if (actionsSetRun) {
    if (actionsSetRun.includes(id)) {
      return <PlayArrow className='actions-icon-loading' fontSize='small' />;
    }
  }

  return (
    <PlayArrow
      data-id={id}
      className='actions-icon'
      onClick={runFunction}
      fontSize='small'
    />
  );
};

export const StopBtn = ({
  taskId,
  actionId,
  status,
  actionsSetStop,
  runFunction,
  role,
}) => {
  if (role !== roles.admin.name) return null;
  if (!isBusyAction(status)) return null;

  if (actionsSetStop) {
    if (actionsSetStop.includes(actionId)) {
      return <RiStopFill className='actions-icon-stop actions-icon-loading' />;
    }
  }

  return (
    <RiStopFill
      data-task={taskId}
      data-action={actionId}
      className='actions-icon-stop actions-icon'
      onClick={runFunction}
    />
  );
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: disableColor,
    color: primaryColor,
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

export const InfoTooltip = ({
  text,
  align,
  className,
  children,
  placement,
}) => {
  return (
    <LightTooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      title={text ? text : children}
      placement={placement ? placement : 'bottom-start'}
    >
      <span
        style={{ verticalAlign: align ? align : 'top' }}
        className={className ? `info-icon ${className}` : 'info-icon'}
      >
        i
      </span>
    </LightTooltip>
  );
};

export const RequiredField = () => {
  return (
    <LightTooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      title='Required Field'
      placement='bottom-start'
    >
      <span className='required-icon'>*</span>
    </LightTooltip>
  );
};

//String to JSX parser
const getNodes = (str) =>
  new DOMParser().parseFromString(str, 'text/html').body.childNodes;

const createJSX = (nodeArray) => {
  //const className = nodeArray[0].className;
  return nodeArray.map((node) => {
    let attributeObj = {};
    const { attributes, localName, childNodes, nodeValue } = node;
    if (attributes) {
      Array.from(attributes).forEach((attribute) => {
        if (attribute.name === 'style') {
          let styleAttributes = attribute.nodeValue.split(';');
          let styleObj = {};
          styleAttributes.forEach((attribute) => {
            let [key, value] = attribute.split(':');
            styleObj[key] = value;
          });
          attributeObj[attribute.name] = styleObj;
        } else {
          attributeObj[attribute.name] = attribute.nodeValue;
        }
      });
    }
    return localName
      ? React.createElement(
          localName,
          attributeObj,
          childNodes && Array.isArray(Array.from(childNodes))
            ? createJSX(Array.from(childNodes))
            : []
        )
      : nodeValue;
  });
};

export const StringToJSX = (props) => {
  return createJSX(Array.from(getNodes(props.domString)));
};
