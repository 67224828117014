import { useState } from 'react';
import { connect } from 'react-redux';
import { PageLoader } from '../../../utils/components';
import { getActionLogs, openConnectorModal } from '../../../actions/connector';
import {
  getActionFromToDate,
  calcDate,
  qtyPerPage
} from '../../../utils/constants';
import DatePicker from 'react-datepicker';
import { MenuItem, Select, Input } from '@material-ui/core';
import { ArrowForwardIos, Visibility } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { FaFileCsv } from 'react-icons/fa';
import { downloadOldLogs } from '../ActionOld.service';

const ActionLogsOld = ({
  connector: { actionLogs, logsLoading },
  actionData,
  getActionLogs,
  openConnectorModal
}) => {
  const { id } = actionData;
  const minDate = calcDate(365);
  const maxDate = new Date();

  const [logsData, setLogsData] = useState({
    from: calcDate(1),
    to: new Date(),
    type: 'all'
  });
  const [page, setPage] = useState(1);

  const headers = [
    { label: 'TYPE', key: 'type' },
    { label: 'DATE', key: 'created_at' },
    { label: 'MESSAGE', key: 'message' }
  ];

  const setFromDate = (date) => {
    const { from, to } = getActionFromToDate(date, logsData.to);
    setLogsData({ ...logsData, from: date });
    getActionLogs({
      id,
      date_from: from,
      date_to: to
    });
  };

  const setToDate = (date) => {
    const { from, to } = getActionFromToDate(logsData.from, date);
    setLogsData({ ...logsData, to: date });
    getActionLogs({
      id,
      date_from: from,
      date_to: to
    });
  };

  const changeType = (event) => {
    setLogsData({
      ...logsData,
      type: event.target.value
    });
  };

  const setPageChange = (e, value) => {
    setPage(value);
  };

  const openMessage = (e) => {
    const messageId = +e.currentTarget.getAttribute('data-id');
    const log = actionLogs.find((l) => +l.id === messageId);
    openConnectorModal({
      id: messageId,
      type: 'log',
      data: log
    });
  };

  const downloadFile = () => {
    downloadOldLogs(headers, actionLogs);
  };

  return (
    <div className="action-logs-container">
      <div className="action-logs-header">
        <div className="action-logs-date-pickers">
          <span className="action-date-pickers-header">DATE RANGE: </span>
          <div className="action-date-pickers-container">
            <DatePicker
              selected={logsData.from}
              onChange={setFromDate}
              minDate={minDate}
              maxDate={maxDate}
              className="date-picker"
            />
            <ArrowForwardIos className="date-picker-arrow" />
            <DatePicker
              selected={logsData.to}
              onChange={setToDate}
              minDate={minDate}
              maxDate={maxDate}
              className="date-picker"
            />
          </div>
        </div>
        <div className="action-select-container">
          <span className="action-date-pickers-header">TYPE:</span>
          <Select
            value={logsData.type}
            onChange={changeType}
            input={<Input />}
            className="action-select-type"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="debug">Debug</MenuItem>
            <MenuItem value="error">Error</MenuItem>
            <MenuItem value="unknown">Unknown</MenuItem>
          </Select>
        </div>
        <div className="action-csv-container">
          <FaFileCsv className="print-icon" onClick={downloadFile} />
        </div>
        {!logsLoading ? (
          actionLogs.filter((log) => {
            if (log[logsData.type] !== undefined) {
              if (+log[logsData.type] === 1) {
                return true;
              }
              return false;
            }
            if (logsData.type === 'unknown') {
              if (+log.error === 0 && +log.debug === 0) {
                return true;
              } else {
                return false;
              }
            }
            return true;
          }).length <= 100 ? null : (
            <div className="action-pagination-box">
              <Pagination
                classes={{ ul: 'admin-pagination' }}
                count={Math.ceil(actionLogs.length / qtyPerPage)}
                page={page}
                onChange={setPageChange}
                siblingCount={1}
                shape="rounded"
              />
            </div>
          )
        ) : (
          <div className="search-container skeleton-box"></div>
        )}
      </div>
      <div className="action-logs-body">
        <div className="action-logs-table-header">
          {headers.map((h, i) => (
            <div
              key={`action-logs-table-header-${i}`}
              className={`actions-table-header-cell`}
            >
              {h.label}
            </div>
          ))}
        </div>
        {logsLoading ? (
          <PageLoader height="70vh" />
        ) : (
          <div className="action-logs-table-body">
            {actionLogs
              .filter((log) => {
                if (log[logsData.type] !== undefined) {
                  if (+log[logsData.type] === 1) {
                    return true;
                  }
                  return false;
                }
                if (logsData.type === 'unknown') {
                  if (+log.error === 0 && +log.debug === 0) {
                    return true;
                  } else {
                    return false;
                  }
                }
                return true;
              })
              .filter(
                (r, i) => i < page * qtyPerPage && i >= (page - 1) * qtyPerPage
              )
              .map((log, i) => (
                <div key={`log-${i}`} className="action-logs-table-row">
                  <div className="actions-table-body-cell bold">
                    {+log.error === 1
                      ? 'Error'
                      : +log.debug === 1
                      ? 'Debug'
                      : 'Unknown'}
                  </div>
                  <div className="actions-table-body-cell">
                    {log.created_at}
                  </div>
                  <div className="actions-table-body-cell actions-table-body-cell-left">
                    {log.message
                      ? log.message.length > 150
                        ? `${log.message.substring(0, 150)}...`
                        : log.message
                      : ''}
                  </div>
                  <div
                    className="action-item-overlay"
                    onClick={openMessage}
                    data-id={log.id}
                  >
                    <Visibility className="action-message-icon" />
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  connector: state.connector
});
export default connect(mapStateToProps, { getActionLogs, openConnectorModal })(
  ActionLogsOld
);
