import { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { PageLoader } from '../../../utils/components';
import {
  getActionLogsNew,
  openConnectorModal,
  getActionLogsFile
} from '../../../actions/connector';
import {
  logTypes,
  recalcTime,
  getActionFromToDate,
  calcDate
} from '../../../utils/constants';
import { setTableFilter } from '../../../utils/tables';
import DatePicker from 'react-datepicker';
import { MenuItem, Select, Input } from '@material-ui/core';
import { ArrowUpward, ArrowForwardIos } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { FaFileCsv } from 'react-icons/fa';
import ClipLoader from 'react-spinners/ClipLoader';
import { buildActionParams } from '../ActionNew.service';
import { ActionLogMessage } from '../../../UI/ActionLogMessage/ActionLogMessage';

const ActionLogsNew = ({
  connector: {
    actionLogsNew,
    logsLoadingNew,
    actionLogsPages,
    loadingLogsFile
  },
  user,
  actionData,
  getActionLogsNew,
  openConnectorModal,
  getActionLogsFile
}) => {
  const { id } = actionData;
  const minDate = useMemo(() => calcDate(365), []);
  const maxDate = useMemo(() => new Date(), []);
  const initialState = useMemo(
    () => ({
      id,
      level: 'all',
      method: 'dsc',
      prop: 'id',
      page: 1,
      from: calcDate(7),
      to: new Date()
    }),
    [id]
  );
  const headers = [
    { label: 'TYPE', key: 'level' },
    { label: 'DATE', key: 'created-at' },
    { label: 'LOG ID', key: 'id' },
    { label: 'TASK ID', key: 'taskId' },
    { label: 'MESSAGE', key: 'message' }
  ];

  const [logsData, setLogsData] = useState(initialState);

  useEffect(() => {
    if (initialState.id) {
      getActionLogsNew(buildActionParams(initialState));
    }
  }, [initialState, getActionLogsNew]);

  const setFromDate = (date) => {
    const { from, to } = getActionFromToDate(date, logsData.to);
    setLogsData({ ...logsData, from: date });
    if (logsData.id) {
      getActionLogsNew(buildActionParams({ ...logsData, from, to }));
    }
  };

  const setToDate = (date) => {
    const { from, to } = getActionFromToDate(logsData.from, date);
    setLogsData({ ...logsData, to: date });
    if (logsData.id) {
      getActionLogsNew(buildActionParams({ ...logsData, from, to }));
    }
  };

  const changeType = (event) => {
    const newState = {
      ...logsData,
      level: event.target.value,
      page: 1
    };
    setLogsData(newState);
    if (newState.id) {
      getActionLogsNew(buildActionParams(newState));
    }
  };

  const setPageChange = (e, value) => {
    const newState = { ...logsData, page: value };
    setLogsData(newState);
    if (newState.id) {
      getActionLogsNew(buildActionParams(newState));
    }
  };

  const openMessage = (id) => {
    const log = actionLogsNew.find((l) => l.id === id);
    openConnectorModal({
      id,
      type: 'logNew',
      data: log
    });
  };

  const setColumnFilter = (e) => {
    const props = setTableFilter(e, headers);
    const newState = { ...logsData, ...props };
    setLogsData(newState);
    if (newState.id) {
      getActionLogsNew(buildActionParams(newState));
    }
  };

  const downloadFile = () => {
    getActionLogsFile(headers, buildActionParams(logsData));
  };

  return (
    <div className="action-logs-container">
      <div className="action-logs-header">
        <div className="action-logs-date-pickers">
          <span className="action-date-pickers-header">DATE RANGE: </span>
          <div className="action-date-pickers-container">
            <DatePicker
              selected={logsData.from}
              onChange={setFromDate}
              minDate={minDate}
              maxDate={maxDate}
              className="date-picker"
            />
            <ArrowForwardIos className="date-picker-arrow" />
            <DatePicker
              selected={logsData.to}
              onChange={setToDate}
              minDate={minDate}
              maxDate={maxDate}
              className="date-picker"
            />
          </div>
        </div>
        <div className="action-select-container">
          <span className="action-date-pickers-header">TYPE:</span>
          <Select
            value={logsData.level}
            onChange={changeType}
            input={<Input />}
            className="action-select-type"
          >
            {logTypes.map((type, i) => (
              <MenuItem value={type.value} key={`log-type-${i}`}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="action-csv-container">
          {loadingLogsFile ? (
            <FaFileCsv className="print-icon-disabled" />
          ) : (
            <FaFileCsv className="print-icon" onClick={downloadFile} />
          )}
          {loadingLogsFile ? <ClipLoader color="#f57f20" size={25} /> : null}
        </div>
        {!actionLogsNew.length ? null : (
          <div className="action-pagination-box">
            <Pagination
              classes={{ ul: 'admin-pagination' }}
              count={actionLogsPages}
              page={logsData.page}
              onChange={setPageChange}
              siblingCount={1}
              shape="rounded"
            />
          </div>
        )}
      </div>
      <div className="action-logs-body">
        <div className="action-logs-table-header-new">
          {headers.map((h, i) => (
            <div
              key={`action-logs-table-header-${i}`}
              className={`actions-table-header-cell`}
            >
              <ArrowUpward
                className={`filter-arrow ${
                  logsData.prop === h.key && logsData.method
                    ? logsData.method === 'asc'
                      ? 'active-arrow-asc'
                      : 'active-arrow-dsc'
                    : ''
                }`}
              />
              <span className="table-filter-header" onClick={setColumnFilter}>
                {h.label}
              </span>
              <ArrowUpward className="filter-arrow-hover" />
            </div>
          ))}
        </div>
        {logsLoadingNew ? (
          <PageLoader height="60vh" />
        ) : (
          <div className="action-logs-table-body">
            {actionLogsNew.map((log, i) => (
              <div key={`log-${i}`} className="action-logs-table-row-new">
                <div className="actions-table-body-cell bold">
                  {log.level ? log.level : 'Unknown'}
                </div>
                <div className="actions-table-body-cell">
                  {log['created-at']
                    ? recalcTime(log['created-at'], user)
                    : 'Unknown'}
                </div>
                <div
                  className="actions-table-body-cell actions-link-cell"
                  onClick={() => openMessage(log.id)}
                >
                  {log.id ? log.id : 'Unknown'}
                </div>
                <div className="actions-table-body-cell">
                  {log.taskId ? log.taskId : 'Unknown'}
                </div>
                <ActionLogMessage
                  message={log.message}
                  className="actions-table-body-cell actions-table-body-cell-left actions-html"
                  isShort={true}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  connector: state.connector,
  user: state.auth.user
});

export default connect(mapStateToProps, {
  getActionLogsNew,
  openConnectorModal,
  getActionLogsFile
})(ActionLogsNew);
