import React, { useEffect, useMemo } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { PageLoader } from '../../utils/components';
import { getActionSettings, getActionLogs } from '../../actions/connector';
import { formatActionDate, calcDate } from '../../utils/constants';
import ActionLogsOld from './components/ActionLogsOld';
import ActionSettingsOld from './components/ActionSettingsOld';
import { formActionTabUrl } from '../../utils/services';

import { Settings, ListAlt } from '@material-ui/icons';
import ActionHeaderOld from './components/ActionHeaderOld';
import { PageContainer } from '../../widgets/PageContainer/PageContainer';

const ActionOld = ({
  connector: { actions, loading },
  getActionSettings,
  getActionLogs,
  tabActive = 0
}) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const action = useMemo(
    () => actions.find((a) => +a.id === +id),
    [actions, id]
  );

  useEffect(() => {
    getActionSettings(id);
    getActionLogs({
      id,
      date_from: formatActionDate(calcDate(1)),
      date_to: formatActionDate(new Date())
    });
  }, [id, getActionSettings, getActionLogs]);

  return (
    <PageContainer
      breadComponent={
        <Link to="/settings/add/action/" className="breadcrumbs-add-btn">
          + Add new action
        </Link>
      }
      breadPath={[
        { name: 'My applications', link: '/dashboard/' },
        { name: 'Action Center (Old)', link: '/actionsold/' },
        {
          name: action ? action.group_name : '',
          link: action ? `/actionsold/${action.group_name}/` : ''
        },
        { name: action ? action.name : '' }
      ]}
      breadLoading={loading}
    >
      {loading ? (
        <PageLoader height="70vh" />
      ) : (
        <>
          <ActionHeaderOld actionData={action} />
          <div className="action-tabs-container">
            <Link
              className={`action-tab-container  ${
                tabActive === 0 ? 'action-tab-active' : ''
              }`}
              to={`${formActionTabUrl(pathname)}logs/`}
            >
              <ListAlt fontSize="small" />
              <span className="icon-name-text">Logs</span>
            </Link>
            <Link
              className={`action-tab-container  ${
                tabActive === 1 ? 'action-tab-active' : ''
              }`}
              to={`${formActionTabUrl(pathname)}settings/`}
            >
              <Settings fontSize="small" />
              <span className="icon-name-text">Settings</span>
            </Link>
          </div>
          <div>
            <div className={tabActive === 0 ? 'active-tab' : 'disabled-tab'}>
              <ActionLogsOld actionData={action ? action : {}} />
            </div>
            <div className={tabActive === 1 ? 'active-tab' : 'disabled-tab'}>
              <ActionSettingsOld />
            </div>
          </div>
        </>
      )}
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  connector: state.connector
});
export default connect(mapStateToProps, { getActionSettings, getActionLogs })(
  ActionOld
);
