import axios from 'axios';
import ClockLoader from 'react-spinners/ClockLoader';
import BarLoader from 'react-spinners/BarLoader';
import { IoWarningOutline } from 'react-icons/io5';

import amazonLogo from '../img/amazon_logo.png';
import ebayLogo from '../img/ebay_logo.png';
import gunBrokerLogo from '../img/gb_logo.png';
import walmartLogo from '../img/walmart_logo.png';
import bigcommerceLogo from '../img/bc_logo.png';
import checkGreen from '../img/check_green.png';

//Marketplace Icons
import amazon from '../img/markeplaces/amazon.png';
import bigcommerce from '../img/markeplaces/bigcommerce.png';
import ebay from '../img/markeplaces/ebay.png';
import gunbroker from '../img/markeplaces/gunbroker.png';
import walmart from '../img/markeplaces/walmart.png';
import others from '../img/markeplaces/others.png';
import othersBlack from '../img/markeplaces/others_black.png';

//Distributor Icons
import aw from '../img/distribs/2AW.png';
import ac from '../img/distribs/AC.png';
import ban from '../img/distribs/BAN.png';
import bh from '../img/distribs/BH.png';
import brs from '../img/distribs/BRS.png';
import css from '../img/distribs/CSS.png';
import gas from '../img/distribs/GAS.png';
import hic from '../img/distribs/HIC.png';
import kroll from '../img/distribs/Kroll.png';
import lip from '../img/distribs/LIP.png';
import petra from '../img/distribs/PETRA.png';
import rsr from '../img/distribs/RSR.png';
import ss from '../img/distribs/SS.png';
import zsg from '../img/distribs/ZSG.png';

export const serverUrl = '/api/connector/new'; //'/api/connector/test';

export const primaryColor = '#2f3440';
export const secondaryColor = '#dd7e43';
export const disableColor = '#f6f6f6';
export const emailReceiver = 'hello@gritr.app';

export const nsItemUrl =
  'https://969904.app.netsuite.com/app/common/item/item.nl?id=';

export const qtyPerPage = 100;

export const roles = {
  admin: {
    name: 'admin',
    label: 'Admin'
  },
  support: {
    name: 'support',
    label: 'Support'
  },
  superadmin: {
    name: 'superadmin',
    label: 'Superadmin'
  }
};

export const logTypes = [
  { name: 'All', value: 'all' },
  { name: 'Info', value: 'INFO' },
  { name: 'Error', value: 'ERROR' },
  { name: 'Fatal', value: 'FATAL' },
  { name: 'Warning', value: 'WARN' },
  { name: 'Debug', value: 'DEBUG' },
  { name: 'Unknown', value: 'UNKNOWN' }
];

export const dbLogTypesList = [
  { name: 'Action', value: 'Action' },
  { name: 'Feed', value: 'Feed' },
  { name: 'User', value: 'User' },
  { name: 'Settings', value: 'Settings' }
];

export const dbLogTypes = {
  action: 'Action',
  feed: 'Feed',
  user: 'User',
  settings: 'Settings'
};

export const getDbLogMessage = (action, name, group) =>
  `${action} -> ${name} ${group ? `(${group})` : ''}`;

export const isBusyAction = (status) =>
  status === 'IN_PROGRESS' ||
  status === 'DELAYED' ||
  status === 'Processing' ||
  status === 'Delayed';

export const markets = [
  {
    name: 'Amazon',
    img: amazonLogo
  },
  {
    name: 'BigCommerce',
    img: bigcommerceLogo
  },
  {
    name: 'GunBroker',
    img: gunBrokerLogo
  },
  {
    name: 'eBay',
    img: ebayLogo
  },
  {
    name: 'Walmart',
    img: walmartLogo
  },
  {
    name: 'Others',
    img: othersBlack
  },
  {
    name: 'Distributors',
    img: othersBlack
  }
];

export const getActionStatus = (status) => {
  const statuses = [
    { name: 'active', img: checkGreen },
    {
      name: 'Processing',
      img: <BarLoader height={4} width={40} color={'#17a2b8'} />
    },
    {
      name: 'Delayed',
      img: <ClockLoader size={15} color={'#17a2b8'} />
    },
    {
      name: 'IN_PROGRESS',
      img: <BarLoader height={4} width={40} color={'#17a2b8'} />
    },
    {
      name: 'DELAYED',
      img: <ClockLoader size={15} color={'#17a2b8'} />
    },
    {
      name: 'INTERRUPTED',
      img: (
        <div className="status-container">
          <IoWarningOutline className="status-icon warning-icon" />
          Interrupted
        </div>
      )
    }
  ];
  return statuses.find((s) => s.name === status);
};

export const getGroupStatus = ({ group, actions, actionGroup }) => {
  const status = getActionStatus('active');
  const market =
    markets.find((m) => actionGroup.marketplace === m.name) ||
    markets.find((m) => 'Others' === m.name);

  let hasProblems = false;
  const groupId = +group?.id;
  let accounts = [];
  const groupActions = actions.filter((a) => a.groups?.includes(groupId));
  const actionsNumber = groupActions.length;
  groupActions.forEach((a) => {
    if (a.status === 'INTERRUPTED') hasProblems = true;
  });
  actions.forEach((a) => {
    if (a.groups?.includes(groupId) && a.accounts) {
      accounts = [...accounts, ...a.accounts];
    }
  });
  accounts = [...new Set(accounts)];
  accounts.sort((a, b) => +a - +b);

  return {
    status,
    hasProblems,
    market,
    actionsNumber,
    accounts
  };
};

// export const modalTypes = {
//   log: 'log',
//   actionOldEdit: ''
// };

export const calcDate = (days) => {
  const today = new Date();
  return new Date(today.setDate(today.getDate() - days));
};

export const formatActionDate = (custom) => {
  const month = custom.getMonth() + 1;
  const day = custom.getDate();
  return `${custom.getFullYear()}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;
};

export const getActionFromToDate = (fromDate, toDate) => {
  const dates = {
    from: formatActionDate(fromDate),
    to: formatActionDate(toDate)
  };

  if (fromDate.getTime() > toDate.getTime()) {
    dates.from = formatActionDate(toDate);
    dates.to = formatActionDate(fromDate);
  }
  return dates;
};

export const formatMessageDate = (d) =>
  new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric'
  }).format(d);

export const formatNumber = (num) => new Intl.NumberFormat('en-US').format(num);
export const formatCurrency = (num) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(num);

export const feedStatuses = [
  {
    label: 'Not Defined',
    key: 0,
    text: 'Items that are waiting identification process'
  },
  {
    label: 'Not Connected',
    key: 1,
    text: 'Items that have passed the identification process and weren’t found in NetSuite. You need to create these items in NetSuite if you want to connect them'
  },
  {
    label: 'Doubles',
    key: 2,
    text: 'Items with duplicate UPCs found during the process of identification. The most common reason for doubles is that a box of items and one item have the same UPC in the distributor’s file. We do not recommend connecting them until you clearly identified which one is the best match for your NetSuite item'
  },
  {
    label: 'Ready to Connect',
    key: 3,
    text: 'Items that have passed the identification process and were found in NetSuite. You can mark them and start the connection process'
  },
  {
    label: 'Connected',
    key: 4,
    text: 'Items that were connected to NetSuite and will be further updated in their prices and quantities to be up to date for further sale from your marketplaces'
  }
];

export const downloadFeedFile = async (id) => {
  //Disable btn
  document.querySelectorAll('.feeds-download-icon').forEach((icon) => {
    if (
      icon.getAttribute('data-id') === id &&
      !icon.classList.contains('disabled-tab')
    ) {
      icon.classList.add('disabled-tab');
    }
  });
  document.querySelectorAll('.feeds-download-icon-loading').forEach((icon) => {
    if (icon.getAttribute('data-id') === id) {
      icon.classList.remove('disabled-tab');
    }
  });
  const btn = document.querySelector('#download-file-btn');
  if (btn) btn.disabled = true;

  try {
    const res = await axios.get(
      `/api/connector/old?url1=items_feeds&url2=items_all_fields?items_feed_id=${id}&status=${feedStatuses
        .map((status) => status.key)
        .join(',')}`
    );
    //Build CSV file
    if (res.data.data) {
      const csvString = [
        res.data.data.columns,
        ...res.data.data.data.map((arr) =>
          arr
            ? arr.map((item) => {
                //console.log(item);
                return item ? String(item).replace(/,/g, ' ') : '';
              })
            : []
        )
      ]
        .map((e) => e.join(','))
        .join('\n');

      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const fileUrl = URL.createObjectURL(blob);
      link.setAttribute('href', fileUrl);
      link.setAttribute('download', `feed_file_id_${id}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (err) {
    console.log(err);
  }

  //Enable btn
  document.querySelectorAll('.feeds-download-icon').forEach((icon) => {
    if (icon.getAttribute('data-id') === id) {
      icon.classList.remove('disabled-tab');
    }
  });
  document.querySelectorAll('.feeds-download-icon-loading').forEach((icon) => {
    if (
      icon.getAttribute('data-id') === id &&
      !icon.classList.contains('disabled-tab')
    ) {
      icon.classList.add('disabled-tab');
    }
  });
  if (btn) btn.disabled = false;
};

export const marketplaces = [
  { name: 'Amazon', value: 'Amazon', icon: amazon },
  { name: 'BigCommerce', value: 'BigCommerce', icon: bigcommerce },
  { name: 'Distributors', value: 'Distributors', icon: others },
  { name: 'eBay', value: 'eBay', icon: ebay },
  { name: 'GunBroker', value: 'GunBroker', icon: gunbroker },
  { name: 'Other', value: 'Other', icon: others },
  { name: 'Others', value: 'Others', icon: others },
  { name: 'Shopify', value: 'Shopify', icon: others },
  { name: 'Walmart', value: 'Walmart', icon: walmart }
];

export const includeOptions = [
  { name: 'Basic Actions', value: 'Basic Actions' },
  { name: 'Support Actions', value: 'Support Actions' },
  { name: 'Reports', value: 'Reports' },
  { name: 'OpenBox Actions', value: 'OpenBox Actions' }
];

export const urgentLevels = [
  { name: '---', value: '-' },
  { name: 'Urgent', value: 'Urgent' },
  { name: 'Hight', value: 'Hight' },
  { name: 'Normal', value: 'Normal' },
  { name: 'Low', value: 'Low' }
];

export const actionTypes = [
  { name: 'Connect Items', value: 'Connect Items' },
  { name: 'Update quantity', value: 'Update quantity' },
  { name: 'Update prices', value: 'Update prices' },
  {
    name: 'Import Orders (including Tracking Export)',
    value: 'Import Orders (including Tracking Export)'
  },
  { name: 'Check Discrepancies', value: 'Check Discrepancies' },
  { name: 'Update product data', value: 'Update product data' },
  { name: 'Update images', value: 'Update images' },
  { name: 'Disconnect Items', value: 'Disconnect Items' },
  { name: 'Synchronize Categories', value: 'Synchronize Categories' },
  { name: 'Other', value: 'Other' }
];

export const conditionTypes = [
  { name: 'New', value: 'New' },
  { name: 'OpenBox', value: 'OpenBox' }
];

export const emailHeader = ({ id, company, email }) => {
  return `<table style="border: 1px solid black;border-collapse: collapse;padding: 5px 10px; font-size: 14px;">
      <tr style="background-color: #ccc;">
        <th style="text-align: center;padding: 5px 10px;border: 1px solid black;">Field Name</th>
        <th style="text-align: center;padding: 5px 10px;border: 1px solid black;">Field Value</th>
      </tr>
      <tr>
          <td style="padding: 5px 10px;border: 1px solid black; text-align: left;">Account ID</a></td>
          <td style="padding: 5px 10px;border: 1px solid black; text-align: left;">${id}</td>
      </tr>
      <tr>
          <td style="padding: 5px 10px;border: 1px solid black; text-align: left;">Account Name</a></td>
          <td style="padding: 5px 10px;border: 1px solid black; text-align: left;">${company}</td>
      </tr>
      <tr>
        <td style="padding: 5px 10px;border: 1px solid black; text-align: left;">User Email</a></td>
        <td style="padding: 5px 10px;border: 1px solid black; text-align: left;">${email}</td>
      </tr>`;
};

export const fileTypes = ['image/', 'pdf', 'text/'];

export const distributorList = [
  { name: '2nd Amendment Wholesale Inc', value: 'aw', icon: aw },
  { name: 'AmChar Wholesale Inc', value: 'ac', icon: ac },
  { name: 'Bangers', value: 'ban', icon: ban },
  { name: 'Big Rock Sports - ACH', value: 'brs', icon: brs },
  { name: 'Bill Hicks & Co, Ltd - ACH', value: 'bh', icon: bh },
  { name: 'Chattanooga Shooting Supplies', value: 'css', icon: css },
  { name: 'Gun Accessory Supply - ACH', value: 'gas', icon: gas },
  { name: 'Hicks', value: 'hic', icon: hic },
  { name: 'Kroll International, LLC', value: 'kroll', icon: kroll },
  { name: 'Lip', value: 'lip', icon: lip },
  { name: 'Petra', value: 'petra', icon: petra },
  { name: 'RSR Group - ACH', value: 'rsr', icon: rsr },
  { name: 'Sports South, LLC - ACH', value: 'ss', icon: ss },
  { name: `Zanders' Sporting Goods`, value: 'zsg', icon: zsg }
];

export const mainCategories = [
  'actionsold',
  'actionsnew',
  'feeds',
  'settings',
  'users',
  'shared',
  'help',
  'logs'
];

export const getTimezoneHours = (timezone) => {
  const today = new Date();
  const todayUTC = new Date(today.toLocaleString('en-US', { timeZone: 'UTC' }));
  const todayTimezone = new Date(
    today.toLocaleString('en-US', { timeZone: timezone })
  );
  return Math.round(
    (todayUTC.getTime() - todayTimezone.getTime()) / (-60 * 60000)
  );
};

const getTimezoneLabel = ({ timezone, label }) => {
  const hours = getTimezoneHours(timezone);
  return `(UTC${hours ? (hours > 0 ? '+' : '-') : ''}${
    hours ? `${('0' + String(Math.abs(hours))).slice(-2)}` : ''
  }) ${label}`;
};

export const timezones = [
  {
    label: getTimezoneLabel({
      timezone: 'America/Los_Angeles',
      label: 'US/Pacific Time'
    }),
    value: 'America/Los_Angeles'
  },
  {
    label: getTimezoneLabel({
      timezone: 'America/Denver',
      label: 'US/Mountain Time'
    }),
    value: 'America/Denver'
  },
  {
    label: getTimezoneLabel({
      timezone: 'America/Chicago',
      label: 'US/Central Time'
    }),
    value: 'America/Chicago'
  },
  {
    label: getTimezoneLabel({ timezone: 'UTC', label: 'UTC' }),
    value: 'UTC'
  },
  {
    label: getTimezoneLabel({
      timezone: 'Europe/London',
      label: 'Europe/London'
    }),
    value: 'Europe/London'
  },
  {
    label: getTimezoneLabel({
      timezone: 'Europe/Warsaw',
      label: 'Europe/Warsaw'
    }),
    value: 'Europe/Warsaw'
  },
  {
    label: getTimezoneLabel({
      timezone: 'Europe/Vilnius',
      label: 'Europe/Vilnius'
    }),
    value: 'Europe/Vilnius'
  },
  {
    label: getTimezoneLabel({
      timezone: 'Europe/Minsk',
      label: 'Europe/Minsk'
    }),
    value: 'Europe/Minsk'
  }
];

export const recalcTime = (date, user) => {
  if (!date) return '';
  const options = { hourCycle: 'h23' };
  if (user) {
    options.timeZone = user.timezone;
  }
  try {
    const current = new Date(date);
    if (isNaN(current.getTime())) return date;
    return current.toLocaleString('en-US', options);
  } catch (error) {
    console.log(error);
  }
  return date;
};

export const formatDate = (custom) => {
  return `${custom.getFullYear()}/${custom.getMonth() + 1}/${custom.getDate()}`;
};
