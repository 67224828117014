import { qtyPerPage, formatDate } from '../../utils/constants';

export const buildActionParams = ({
  id,
  page,
  level,
  prop,
  method,
  from,
  to
}) => {
  const params = {
    id,
    'page[size]': qtyPerPage,
    'page[number]': page,
    sort: `${method === 'dsc' ? '-' : ''}${prop}`,
    'filter[logs.created_at][greater_or_equal]': formatDate(new Date(from)),
    'filter[logs.created_at][less_or_equal]': `${formatDate(
      new Date(to)
    )} 23:59:59`
  };

  if (level !== 'all') params['filter[level]'] = level;

  return params;
};
