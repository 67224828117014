import {
  REGISTER_SUCCESS,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_AUTH_LOADING,
  SET_URL,
  EDIT_USER,
  UPDATE_PROFILE,
  START_TRACKING,
  ADD_PORTLET,
  REMOVE_PORTLET,
  CHANGE_PORTLET,
  ADD_FAVOURITE_ACTION,
  REMOVE_FAVOURITE_ACTION
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: false,
  auth_loading: true,
  user: {
    portlets: [],
    favouriteActions: []
  },
  profile: {},
  sharedAccess: [],
  url: null,
  tracking: false
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        auth_loading: false
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false
      };
    case EDIT_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload
        }
      };
    case SET_AUTH_LOADING:
      return {
        ...state,
        loading: true,
        auth_loading: true
      };
    case LOGIN_FAIL:
      localStorage.removeItem('token');
      return {
        ...initialState,
        token: null,
        auth_loading: false
      };
    case SET_URL:
      return {
        ...state,
        url: payload
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...payload
        }
      };
    case ADD_PORTLET:
    case CHANGE_PORTLET:
    case REMOVE_PORTLET:
      return {
        ...state,
        user: {
          ...state.user,
          portlets: payload
        }
      };
    case ADD_FAVOURITE_ACTION:
      return {
        ...state,
        user: {
          ...state.user,
          favouriteActions: [payload, ...state.user.favouriteActions]
        }
      };
    case REMOVE_FAVOURITE_ACTION:
      return {
        ...state,
        user: {
          ...state.user,
          favouriteActions: state.user.favouriteActions.filter(
            (a) => a._id !== payload
          )
        }
      };
    case START_TRACKING:
      return {
        ...state,
        tracking: true
      };
    case LOGOUT:
      localStorage.removeItem('token');
      return {
        ...initialState,
        token: null
      };

    default:
      return state;
  }
}
