import EveryMin from './EveryMin';
import EveryHour from './EveryHour';
import Monthly from './Monthly';
import Weekly from './Weekly';
import Daily from './Daily';

const ScheduleMain = ({ type, timezone, setCron, defaultCron }) => {
  switch (type) {
    case 'everymin':
      return (
        <EveryMin
          timezone={timezone}
          setCron={setCron}
          defaultCron={defaultCron}
        />
      );
    case 'everyhour':
      return (
        <EveryHour
          timezone={timezone}
          setCron={setCron}
          defaultCron={defaultCron}
        />
      );
    case 'daily':
      return (
        <Daily
          timezone={timezone}
          setCron={setCron}
          defaultCron={defaultCron}
        />
      );
    case 'weekly':
      return (
        <Weekly
          timezone={timezone}
          setCron={setCron}
          defaultCron={defaultCron}
        />
      );
    case 'monthly':
      return (
        <Monthly
          timezone={timezone}
          setCron={setCron}
          defaultCron={defaultCron}
        />
      );
    default:
      return <div className='schedule-sublist-container'></div>;
  }
};

export default ScheduleMain;
