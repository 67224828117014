//Auth methods
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const LOGOUT = 'LOGOUT';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const SET_URL = 'SET_URL';
export const EDIT_USER = 'EDIT_USER';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const START_TRACKING = 'START_TRACKING';
export const ADD_PORTLET = 'ADD_PORTLET';
export const REMOVE_PORTLET = 'REMOVE_PORTLET';
export const CHANGE_PORTLET = 'CHANGE_PORTLET';
export const ADD_FAVOURITE_ACTION = 'ADD_FAVOURITE_ACTION';
export const REMOVE_FAVOURITE_ACTION = 'REMOVE_FAVOURITE_ACTION';

//Profile methods
export const ADD_PROFILE_USER = 'ADD_PROFILE_USER';
export const EDIT_PROFILE_USER = 'EDIT_PROFILE_USER';
export const DELETE_PROFILE_USER = 'DELETE_PROFILE_USER';
export const GET_PROFILE_USERS = 'GET_PROFILE_USERS';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const GET_PROFILE_ACCOUNTS = 'GET_PROFILE_ACCOUNTS';
export const SET_EMAIL_LOADING = 'SET_EMAIL_LOADING';
export const UPDATE_ACCOUNT_SETTINGS = 'UPDATE_ACCOUNT_SETTINGS';
export const GET_PROFILE_LOGS = 'GET_PROFILE_LOGS';
export const SET_PROFILE_LOGS_LOADING = 'SET_PROFILE_LOGS_LOADING';
export const GET_ORDERS_ERRORS = 'GET_ORDERS_ERRORS';
export const SET_ORDERS_ERRORS_LOADING = 'SET_ORDERS_ERRORS_LOADING';

//Connecor methods
export const GET_ACTIONS = 'GET_ACTIONS';
export const CLEAR_CONNECTOR = 'CLEAR_CONNECTOR';
export const OPEN_CONNECTOR_MODAL = 'OPEN_CONNECTOR_MODAL';
export const CLOSE_CONNECTOR_MODAL = 'CLOSE_CONNECTOR_MODAL';
export const GET_ACTION_LOGS = 'GET_ACTION_LOGS';
export const GET_ACTION_SETTINGS = 'GET_ACTION_SETTINGS';
export const RUN_ACTION = 'RUN_ACTION';
export const SET_ACTION_LOGS_LOADING = 'SET_ACTION_LOGS_LOADING';
export const SET_ACTION_SETTINGS_LOADING = 'SET_ACTION_SETTINGS_LOADING';
export const SET_RUN_ACTION_LOADING = 'SET_RUN_ACTION_LOADING';
export const GET_ALL_FEEDS = 'GET_ALL_FEEDS';
export const GET_FEED_ITEMS = 'GET_FEED_ITEMS';
export const SET_LOADING_FEED_ITEMS = 'SET_LOADING_FEED_ITEMS';
export const SEND_FEED_ITEMS = 'SEND_FEED_ITEMS';
export const SET_LOADING_SEND_ITEMS = 'SET_LOADING_SEND_ITEMS';
export const SET_LOADING_IDENTIFY_ITEMS = 'SET_LOADING_IDENTIFY_ITEMS';
export const SET_SELECTED_ITEMS = 'SET_SELECTED_ITEMS';
export const SET_ACTION_LOGS_NEW_LOADING = 'SET_ACTION_LOGS_NEW_LOADING';
export const GET_ACTION_LOGS_NEW = 'GET_ACTION_LOGS_NEW';
export const SELECT_ACTION_GROUP = 'SELECT_ACTION_GROUP';
export const SET_LOADING_SCHEDULE = 'SET_LOADING_SCHEDULE';
export const UPDATE_ACTION_SCHEDULE = 'UPDATE_ACTION_SCHEDULE';
export const STOP_ACTION = 'STOP_ACTION';
export const SET_STOP_ACTION_LOADING = 'SET_STOP_ACTION_LOADING';

//New Connector Methods
export const GET_ACTIONS_NEW = 'GET_ACTIONS_NEW';
export const RUN_ACTION_NEW = 'RUN_ACTION_NEW';
export const UPDATE_ACTION_SETTINGS = 'UPDATE_ACTION_SETTINGS';
export const SET_LOADING_LOGS_FILE = 'SET_LOADING_LOGS_FILE';
export const GET_ACTIONS_UPDATES = 'GET_ACTIONS_UPDATES';

//Connections Methods
export const GET_CONNECTIONS = 'GET_CONNECTIONS';
export const CLEAR_CONNECTIONS = 'CLEAR_CONNECTIONS';
export const SET_CONNECTIONS_LOADING = 'SET_CONNECTIONS_LOADING';

//Public Methods
export const SET_PUBLIC_EMAIL_LOADING = 'SET_PUBLIC_EMAIL_LOADING';
export const GET_CAPTCHA_SCORE = 'GET_CAPTCHA_SCORE';
export const CHECK_RESET_TOKEN = 'CHECK_RESET_TOKEN';

// Shared Access Methods
export const GET_ALL_SHARED_ACCESS = 'GET_ALL_SHARED_ACCESS';
export const DELETE_SHARED_ACCESS = 'DELETE_SHARED_ACCESS';
export const CHANGE_SHARED_ACCESS = 'CHANGE_SHARED_ACCESS';
export const SET_SHARED_ACCESS_LOADING = 'SET_SHARED_ACCESS_LOADING';
export const CLEAR_SHARED_ACCESS = 'CLEAR_SHARED_ACCESS';
export const CHECK_NEW_USER_TOKEN = 'CHECK_NEW_USER_TOKEN';
